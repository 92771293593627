export default Object.freeze({
    api: {
        createRecordUrl: 'https://api-sotaque.gabriel-milan.com/create_record/',
        getGendersUrl: 'https://api-sotaque.gabriel-milan.com/genders/',
        getCitiesUrl: 'https://api-sotaque.gabriel-milan.com/cities/',
        getStatesUrl: 'https://api-sotaque.gabriel-milan.com/states/',
        getSentenceUrl: 'https://api-sotaque.gabriel-milan.com/sentences/',
    },
    genders: [
        "Masculino",
        "Feminino",
        "Outro / Prefiro n\u00e3o informar",
    ],
    cities: {
        RO: [
            "Alta Floresta D'Oeste",
            "Alto Alegre dos Parecis",
            "Alto Para\u00edso",
            "Alvorada D'Oeste",
            "Ariquemes",
            "Buritis",
            "Cabixi",
            "Cacaul\u00e2ndia",
            "Cacoal",
            "Campo Novo de Rond\u00f4nia",
            "Candeias do Jamari",
            "Castanheiras",
            "Cerejeiras",
            "Chupinguaia",
            "Colorado do Oeste",
            "Corumbiara",
            "Costa Marques",
            "Cujubim",
            "Espig\u00e3o D'Oeste",
            "Governador Jorge Teixeira",
            "Guajar\u00e1-Mirim",
            "Itapu\u00e3 do Oeste",
            "Jaru",
            "Ji-Paran\u00e1",
            "Machadinho D'Oeste",
            "Ministro Andreazza",
            "Mirante da Serra",
            "Monte Negro",
            "Nova Brasil\u00e2ndia D'Oeste",
            "Nova Mamor\u00e9",
            "Nova Uni\u00e3o",
            "Novo Horizonte do Oeste",
            "Ouro Preto do Oeste",
            "Parecis",
            "Pimenta Bueno",
            "Pimenteiras do Oeste",
            "Porto Velho",
            "Presidente M\u00e9dici",
            "Primavera de Rond\u00f4nia",
            "Rio Crespo",
            "Rolim de Moura",
            "Santa Luzia D'Oeste",
            "S\u00e3o Felipe D'Oeste",
            "S\u00e3o Francisco do Guapor\u00e9",
            "S\u00e3o Miguel do Guapor\u00e9",
            "Seringueiras",
            "Teixeir\u00f3polis",
            "Theobroma",
            "Urup\u00e1",
            "Vale do Anari",
            "Vale do Para\u00edso",
            "Vilhena"
        ],
        AC: [
            "Acrel\u00e2ndia",
            "Assis Brasil",
            "Brasil\u00e9ia",
            "Bujari",
            "Capixaba",
            "Cruzeiro do Sul",
            "Epitaciol\u00e2ndia",
            "Feij\u00f3",
            "Jord\u00e3o",
            "M\u00e2ncio Lima",
            "Manoel Urbano",
            "Marechal Thaumaturgo",
            "Pl\u00e1cido de Castro",
            "Porto Acre",
            "Porto Walter",
            "Rio Branco",
            "Rodrigues Alves",
            "Santa Rosa do Purus",
            "Sena Madureira",
            "Senador Guiomard",
            "Tarauac\u00e1",
            "Xapuri"
        ],
        AM: [
            "Alvar\u00e3es",
            "Amatur\u00e1",
            "Anam\u00e3",
            "Anori",
            "Apu\u00ed",
            "Atalaia do Norte",
            "Autazes",
            "Barcelos",
            "Barreirinha",
            "Benjamin Constant",
            "Beruri",
            "Boa Vista do Ramos",
            "Boca do Acre",
            "Borba",
            "Caapiranga",
            "Canutama",
            "Carauari",
            "Careiro",
            "Careiro da V\u00e1rzea",
            "Coari",
            "Codaj\u00e1s",
            "Eirunep\u00e9",
            "Envira",
            "Fonte Boa",
            "Guajar\u00e1",
            "Humait\u00e1",
            "Ipixuna",
            "Iranduba",
            "Itacoatiara",
            "Itamarati",
            "Itapiranga",
            "Japur\u00e1",
            "Juru\u00e1",
            "Juta\u00ed",
            "L\u00e1brea",
            "Manacapuru",
            "Manaquiri",
            "Manaus",
            "Manicor\u00e9",
            "Mara\u00e3",
            "Mau\u00e9s",
            "Nhamund\u00e1",
            "Nova Olinda do Norte",
            "Novo Air\u00e3o",
            "Novo Aripuan\u00e3",
            "Parintins",
            "Pauini",
            "Presidente Figueiredo",
            "Rio Preto da Eva",
            "Santa Isabel do Rio Negro",
            "Santo Ant\u00f4nio do I\u00e7\u00e1",
            "S\u00e3o Gabriel da Cachoeira",
            "S\u00e3o Paulo de Oliven\u00e7a",
            "S\u00e3o Sebasti\u00e3o do Uatum\u00e3",
            "Silves",
            "Tabatinga",
            "Tapau\u00e1",
            "Tef\u00e9",
            "Tonantins",
            "Uarini",
            "Urucar\u00e1",
            "Urucurituba"
        ],
        RR: [
            "Alto Alegre",
            "Amajari",
            "Boa Vista",
            "Bonfim",
            "Cant\u00e1",
            "Caracara\u00ed",
            "Caroebe",
            "Iracema",
            "Mucaja\u00ed",
            "Normandia",
            "Pacaraima",
            "Rorain\u00f3polis",
            "S\u00e3o Jo\u00e3o da Baliza",
            "S\u00e3o Luiz",
            "Uiramut\u00e3"
        ],
        PA: [
            "Abaetetuba",
            "Abel Figueiredo",
            "Acar\u00e1",
            "Afu\u00e1",
            "\u00c1gua Azul do Norte",
            "Alenquer",
            "Almeirim",
            "Altamira",
            "Anaj\u00e1s",
            "Ananindeua",
            "Anapu",
            "Augusto Corr\u00eaa",
            "Aurora do Par\u00e1",
            "Aveiro",
            "Bagre",
            "Bai\u00e3o",
            "Bannach",
            "Barcarena",
            "Bel\u00e9m",
            "Belterra",
            "Benevides",
            "Bom Jesus do Tocantins",
            "Bonito",
            "Bragan\u00e7a",
            "Brasil Novo",
            "Brejo Grande do Araguaia",
            "Breu Branco",
            "Breves",
            "Bujaru",
            "Cachoeira do Arari",
            "Cachoeira do Piri\u00e1",
            "Camet\u00e1",
            "Cana\u00e3 dos Caraj\u00e1s",
            "Capanema",
            "Capit\u00e3o Po\u00e7o",
            "Castanhal",
            "Chaves",
            "Colares",
            "Concei\u00e7\u00e3o do Araguaia",
            "Conc\u00f3rdia do Par\u00e1",
            "Cumaru do Norte",
            "Curion\u00f3polis",
            "Curralinho",
            "Curu\u00e1",
            "Curu\u00e7\u00e1",
            "Dom Eliseu",
            "Eldorado do Caraj\u00e1s",
            "Faro",
            "Floresta do Araguaia",
            "Garraf\u00e3o do Norte",
            "Goian\u00e9sia do Par\u00e1",
            "Gurup\u00e1",
            "Igarap\u00e9-A\u00e7u",
            "Igarap\u00e9-Miri",
            "Inhangapi",
            "Ipixuna do Par\u00e1",
            "Irituia",
            "Itaituba",
            "Itupiranga",
            "Jacareacanga",
            "Jacund\u00e1",
            "Juruti",
            "Limoeiro do Ajuru",
            "M\u00e3e do Rio",
            "Magalh\u00e3es Barata",
            "Marab\u00e1",
            "Maracan\u00e3",
            "Marapanim",
            "Marituba",
            "Medicil\u00e2ndia",
            "Melga\u00e7o",
            "Mocajuba",
            "Moju",
            "Moju\u00ed dos Campos",
            "Monte Alegre",
            "Muan\u00e1",
            "Nova Esperan\u00e7a do Piri\u00e1",
            "Nova Ipixuna",
            "Nova Timboteua",
            "Novo Progresso",
            "Novo Repartimento",
            "\u00d3bidos",
            "Oeiras do Par\u00e1",
            "Oriximin\u00e1",
            "Our\u00e9m",
            "Ouril\u00e2ndia do Norte",
            "Pacaj\u00e1",
            "Palestina do Par\u00e1",
            "Paragominas",
            "Parauapebas",
            "Pau d'Arco",
            "Peixe-Boi",
            "Pi\u00e7arra",
            "Placas",
            "Ponta de Pedras",
            "Portel",
            "Porto de Moz",
            "Prainha",
            "Primavera",
            "Quatipuru",
            "Reden\u00e7\u00e3o",
            "Rio Maria",
            "Rondon do Par\u00e1",
            "Rur\u00f3polis",
            "Salin\u00f3polis",
            "Salvaterra",
            "Santa B\u00e1rbara do Par\u00e1",
            "Santa Cruz do Arari",
            "Santa Izabel do Par\u00e1",
            "Santa Luzia do Par\u00e1",
            "Santa Maria das Barreiras",
            "Santa Maria do Par\u00e1",
            "Santana do Araguaia",
            "Santar\u00e9m",
            "Santar\u00e9m Novo",
            "Santo Ant\u00f4nio do Tau\u00e1",
            "S\u00e3o Caetano de Odivelas",
            "S\u00e3o Domingos do Araguaia",
            "S\u00e3o Domingos do Capim",
            "S\u00e3o F\u00e9lix do Xingu",
            "S\u00e3o Francisco do Par\u00e1",
            "S\u00e3o Geraldo do Araguaia",
            "S\u00e3o Jo\u00e3o da Ponta",
            "S\u00e3o Jo\u00e3o de Pirabas",
            "S\u00e3o Jo\u00e3o do Araguaia",
            "S\u00e3o Miguel do Guam\u00e1",
            "S\u00e3o Sebasti\u00e3o da Boa Vista",
            "Sapucaia",
            "Senador Jos\u00e9 Porf\u00edrio",
            "Soure",
            "Tail\u00e2ndia",
            "Terra Alta",
            "Terra Santa",
            "Tom\u00e9-A\u00e7u",
            "Tracuateua",
            "Trair\u00e3o",
            "Tucum\u00e3",
            "Tucuru\u00ed",
            "Ulian\u00f3polis",
            "Uruar\u00e1",
            "Vigia",
            "Viseu",
            "Vit\u00f3ria do Xingu",
            "Xinguara"
        ],
        AP: [
            "Amap\u00e1",
            "Cal\u00e7oene",
            "Cutias",
            "Ferreira Gomes",
            "Itaubal",
            "Laranjal do Jari",
            "Macap\u00e1",
            "Mazag\u00e3o",
            "Oiapoque",
            "Pedra Branca do Amapari",
            "Porto Grande",
            "Pracu\u00faba",
            "Santana",
            "Serra do Navio",
            "Tartarugalzinho",
            "Vit\u00f3ria do Jari"
        ],
        TO: [
            "Abreul\u00e2ndia",
            "Aguiarn\u00f3polis",
            "Alian\u00e7a do Tocantins",
            "Almas",
            "Alvorada",
            "Anan\u00e1s",
            "Angico",
            "Aparecida do Rio Negro",
            "Aragominas",
            "Araguacema",
            "Aragua\u00e7u",
            "Aragua\u00edna",
            "Araguan\u00e3",
            "Araguatins",
            "Arapoema",
            "Arraias",
            "Augustin\u00f3polis",
            "Aurora do Tocantins",
            "Axix\u00e1 do Tocantins",
            "Baba\u00e7ul\u00e2ndia",
            "Bandeirantes do Tocantins",
            "Barra do Ouro",
            "Barrol\u00e2ndia",
            "Bernardo Say\u00e3o",
            "Bom Jesus do Tocantins",
            "Brasil\u00e2ndia do Tocantins",
            "Brejinho de Nazar\u00e9",
            "Buriti do Tocantins",
            "Cachoeirinha",
            "Campos Lindos",
            "Cariri do Tocantins",
            "Carmol\u00e2ndia",
            "Carrasco Bonito",
            "Caseara",
            "Centen\u00e1rio",
            "Chapada da Natividade",
            "Chapada de Areia",
            "Colinas do Tocantins",
            "Colm\u00e9ia",
            "Combinado",
            "Concei\u00e7\u00e3o do Tocantins",
            "Couto Magalh\u00e3es",
            "Cristal\u00e2ndia",
            "Crix\u00e1s do Tocantins",
            "Darcin\u00f3polis",
            "Dian\u00f3polis",
            "Divin\u00f3polis do Tocantins",
            "Dois Irm\u00e3os do Tocantins",
            "Duer\u00e9",
            "Esperantina",
            "F\u00e1tima",
            "Figueir\u00f3polis",
            "Filad\u00e9lfia",
            "Formoso do Araguaia",
            "Fortaleza do Taboc\u00e3o",
            "Goianorte",
            "Goiatins",
            "Guara\u00ed",
            "Gurupi",
            "Ipueiras",
            "Itacaj\u00e1",
            "Itaguatins",
            "Itapiratins",
            "Itapor\u00e3 do Tocantins",
            "Ja\u00fa do Tocantins",
            "Juarina",
            "Lagoa da Confus\u00e3o",
            "Lagoa do Tocantins",
            "Lajeado",
            "Lavandeira",
            "Lizarda",
            "Luzin\u00f3polis",
            "Marian\u00f3polis do Tocantins",
            "Mateiros",
            "Mauril\u00e2ndia do Tocantins",
            "Miracema do Tocantins",
            "Miranorte",
            "Monte do Carmo",
            "Monte Santo do Tocantins",
            "Muricil\u00e2ndia",
            "Natividade",
            "Nazar\u00e9",
            "Nova Olinda",
            "Nova Rosal\u00e2ndia",
            "Novo Acordo",
            "Novo Alegre",
            "Novo Jardim",
            "Oliveira de F\u00e1tima",
            "Palmas",
            "Palmeirante",
            "Palmeiras do Tocantins",
            "Palmeir\u00f3polis",
            "Para\u00edso do Tocantins",
            "Paran\u00e3",
            "Pau D'Arco",
            "Pedro Afonso",
            "Peixe",
            "Pequizeiro",
            "Pindorama do Tocantins",
            "Piraqu\u00ea",
            "Pium",
            "Ponte Alta do Bom Jesus",
            "Ponte Alta do Tocantins",
            "Porto Alegre do Tocantins",
            "Porto Nacional",
            "Praia Norte",
            "Presidente Kennedy",
            "Pugmil",
            "Recursol\u00e2ndia",
            "Riachinho",
            "Rio da Concei\u00e7\u00e3o",
            "Rio dos Bois",
            "Rio Sono",
            "Sampaio",
            "Sandol\u00e2ndia",
            "Santa F\u00e9 do Araguaia",
            "Santa Maria do Tocantins",
            "Santa Rita do Tocantins",
            "Santa Rosa do Tocantins",
            "Santa Tereza do Tocantins",
            "Santa Terezinha do Tocantins",
            "S\u00e3o Bento do Tocantins",
            "S\u00e3o F\u00e9lix do Tocantins",
            "S\u00e3o Miguel do Tocantins",
            "S\u00e3o Salvador do Tocantins",
            "S\u00e3o Sebasti\u00e3o do Tocantins",
            "S\u00e3o Val\u00e9rio",
            "Silvan\u00f3polis",
            "S\u00edtio Novo do Tocantins",
            "Sucupira",
            "Taguatinga",
            "Taipas do Tocantins",
            "Talism\u00e3",
            "Tocant\u00ednia",
            "Tocantin\u00f3polis",
            "Tupirama",
            "Tupiratins",
            "Wanderl\u00e2ndia",
            "Xambio\u00e1"
        ],
        MA: [
            "A\u00e7ail\u00e2ndia",
            "Afonso Cunha",
            "\u00c1gua Doce do Maranh\u00e3o",
            "Alc\u00e2ntara",
            "Aldeias Altas",
            "Altamira do Maranh\u00e3o",
            "Alto Alegre do Maranh\u00e3o",
            "Alto Alegre do Pindar\u00e9",
            "Alto Parna\u00edba",
            "Amap\u00e1 do Maranh\u00e3o",
            "Amarante do Maranh\u00e3o",
            "Anajatuba",
            "Anapurus",
            "Apicum-A\u00e7u",
            "Araguan\u00e3",
            "Araioses",
            "Arame",
            "Arari",
            "Axix\u00e1",
            "Bacabal",
            "Bacabeira",
            "Bacuri",
            "Bacurituba",
            "Balsas",
            "Bar\u00e3o de Graja\u00fa",
            "Barra do Corda",
            "Barreirinhas",
            "Bela Vista do Maranh\u00e3o",
            "Bel\u00e1gua",
            "Benedito Leite",
            "Bequim\u00e3o",
            "Bernardo do Mearim",
            "Boa Vista do Gurupi",
            "Bom Jardim",
            "Bom Jesus das Selvas",
            "Bom Lugar",
            "Brejo",
            "Brejo de Areia",
            "Buriti",
            "Buriti Bravo",
            "Buriticupu",
            "Buritirana",
            "Cachoeira Grande",
            "Cajapi\u00f3",
            "Cajari",
            "Campestre do Maranh\u00e3o",
            "C\u00e2ndido Mendes",
            "Cantanhede",
            "Capinzal do Norte",
            "Carolina",
            "Carutapera",
            "Caxias",
            "Cedral",
            "Central do Maranh\u00e3o",
            "Centro do Guilherme",
            "Centro Novo do Maranh\u00e3o",
            "Chapadinha",
            "Cidel\u00e2ndia",
            "Cod\u00f3",
            "Coelho Neto",
            "Colinas",
            "Concei\u00e7\u00e3o do Lago-A\u00e7u",
            "Coroat\u00e1",
            "Cururupu",
            "Davin\u00f3polis",
            "Dom Pedro",
            "Duque Bacelar",
            "Esperantin\u00f3polis",
            "Estreito",
            "Feira Nova do Maranh\u00e3o",
            "Fernando Falc\u00e3o",
            "Formosa da Serra Negra",
            "Fortaleza dos Nogueiras",
            "Fortuna",
            "Godofredo Viana",
            "Gon\u00e7alves Dias",
            "Governador Archer",
            "Governador Edison Lob\u00e3o",
            "Governador Eug\u00eanio Barros",
            "Governador Luiz Rocha",
            "Governador Newton Bello",
            "Governador Nunes Freire",
            "Gra\u00e7a Aranha",
            "Graja\u00fa",
            "Guimar\u00e3es",
            "Humberto de Campos",
            "Icatu",
            "Igarap\u00e9 do Meio",
            "Igarap\u00e9 Grande",
            "Imperatriz",
            "Itaipava do Graja\u00fa",
            "Itapecuru Mirim",
            "Itinga do Maranh\u00e3o",
            "Jatob\u00e1",
            "Jenipapo dos Vieiras",
            "Jo\u00e3o Lisboa",
            "Josel\u00e2ndia",
            "Junco do Maranh\u00e3o",
            "Lago da Pedra",
            "Lago do Junco",
            "Lago dos Rodrigues",
            "Lago Verde",
            "Lagoa do Mato",
            "Lagoa Grande do Maranh\u00e3o",
            "Lajeado Novo",
            "Lima Campos",
            "Loreto",
            "Lu\u00eds Domingues",
            "Magalh\u00e3es de Almeida",
            "Maraca\u00e7um\u00e9",
            "Maraj\u00e1 do Sena",
            "Maranh\u00e3ozinho",
            "Mata Roma",
            "Matinha",
            "Mat\u00f5es",
            "Mat\u00f5es do Norte",
            "Milagres do Maranh\u00e3o",
            "Mirador",
            "Miranda do Norte",
            "Mirinzal",
            "Mon\u00e7\u00e3o",
            "Montes Altos",
            "Morros",
            "Nina Rodrigues",
            "Nova Colinas",
            "Nova Iorque",
            "Nova Olinda do Maranh\u00e3o",
            "Olho d'\u00c1gua das Cunh\u00e3s",
            "Olinda Nova do Maranh\u00e3o",
            "Pa\u00e7o do Lumiar",
            "Palmeir\u00e2ndia",
            "Paraibano",
            "Parnarama",
            "Passagem Franca",
            "Pastos Bons",
            "Paulino Neves",
            "Paulo Ramos",
            "Pedreiras",
            "Pedro do Ros\u00e1rio",
            "Penalva",
            "Peri Mirim",
            "Peritor\u00f3",
            "Pindar\u00e9-Mirim",
            "Pinheiro",
            "Pio XII",
            "Pirapemas",
            "Po\u00e7\u00e3o de Pedras",
            "Porto Franco",
            "Porto Rico do Maranh\u00e3o",
            "Presidente Dutra",
            "Presidente Juscelino",
            "Presidente M\u00e9dici",
            "Presidente Sarney",
            "Presidente Vargas",
            "Primeira Cruz",
            "Raposa",
            "Riach\u00e3o",
            "Ribamar Fiquene",
            "Ros\u00e1rio",
            "Samba\u00edba",
            "Santa Filomena do Maranh\u00e3o",
            "Santa Helena",
            "Santa In\u00eas",
            "Santa Luzia",
            "Santa Luzia do Paru\u00e1",
            "Santa Quit\u00e9ria do Maranh\u00e3o",
            "Santa Rita",
            "Santana do Maranh\u00e3o",
            "Santo Amaro do Maranh\u00e3o",
            "Santo Ant\u00f4nio dos Lopes",
            "S\u00e3o Benedito do Rio Preto",
            "S\u00e3o Bento",
            "S\u00e3o Bernardo",
            "S\u00e3o Domingos do Azeit\u00e3o",
            "S\u00e3o Domingos do Maranh\u00e3o",
            "S\u00e3o F\u00e9lix de Balsas",
            "S\u00e3o Francisco do Brej\u00e3o",
            "S\u00e3o Francisco do Maranh\u00e3o",
            "S\u00e3o Jo\u00e3o Batista",
            "S\u00e3o Jo\u00e3o do Car\u00fa",
            "S\u00e3o Jo\u00e3o do Para\u00edso",
            "S\u00e3o Jo\u00e3o do Soter",
            "S\u00e3o Jo\u00e3o dos Patos",
            "S\u00e3o Jos\u00e9 de Ribamar",
            "S\u00e3o Jos\u00e9 dos Bas\u00edlios",
            "S\u00e3o Lu\u00eds",
            "S\u00e3o Lu\u00eds Gonzaga do Maranh\u00e3o",
            "S\u00e3o Mateus do Maranh\u00e3o",
            "S\u00e3o Pedro da \u00c1gua Branca",
            "S\u00e3o Pedro dos Crentes",
            "S\u00e3o Raimundo das Mangabeiras",
            "S\u00e3o Raimundo do Doca Bezerra",
            "S\u00e3o Roberto",
            "S\u00e3o Vicente Ferrer",
            "Satubinha",
            "Senador Alexandre Costa",
            "Senador La Rocque",
            "Serrano do Maranh\u00e3o",
            "S\u00edtio Novo",
            "Sucupira do Norte",
            "Sucupira do Riach\u00e3o",
            "Tasso Fragoso",
            "Timbiras",
            "Timon",
            "Trizidela do Vale",
            "Tufil\u00e2ndia",
            "Tuntum",
            "Turia\u00e7u",
            "Turil\u00e2ndia",
            "Tut\u00f3ia",
            "Urbano Santos",
            "Vargem Grande",
            "Viana",
            "Vila Nova dos Mart\u00edrios",
            "Vit\u00f3ria do Mearim",
            "Vitorino Freire",
            "Z\u00e9 Doca"
        ],
        PI: [
            "Acau\u00e3",
            "Agricol\u00e2ndia",
            "\u00c1gua Branca",
            "Alagoinha do Piau\u00ed",
            "Alegrete do Piau\u00ed",
            "Alto Long\u00e1",
            "Altos",
            "Alvorada do Gurgu\u00e9ia",
            "Amarante",
            "Angical do Piau\u00ed",
            "An\u00edsio de Abreu",
            "Ant\u00f4nio Almeida",
            "Aroazes",
            "Aroeiras do Itaim",
            "Arraial",
            "Assun\u00e7\u00e3o do Piau\u00ed",
            "Avelino Lopes",
            "Baixa Grande do Ribeiro",
            "Barra D'Alc\u00e2ntara",
            "Barras",
            "Barreiras do Piau\u00ed",
            "Barro Duro",
            "Batalha",
            "Bela Vista do Piau\u00ed",
            "Bel\u00e9m do Piau\u00ed",
            "Beneditinos",
            "Bertol\u00ednia",
            "Bet\u00e2nia do Piau\u00ed",
            "Boa Hora",
            "Bocaina",
            "Bom Jesus",
            "Bom Princ\u00edpio do Piau\u00ed",
            "Bonfim do Piau\u00ed",
            "Boqueir\u00e3o do Piau\u00ed",
            "Brasileira",
            "Brejo do Piau\u00ed",
            "Buriti dos Lopes",
            "Buriti dos Montes",
            "Cabeceiras do Piau\u00ed",
            "Cajazeiras do Piau\u00ed",
            "Cajueiro da Praia",
            "Caldeir\u00e3o Grande do Piau\u00ed",
            "Campinas do Piau\u00ed",
            "Campo Alegre do Fidalgo",
            "Campo Grande do Piau\u00ed",
            "Campo Largo do Piau\u00ed",
            "Campo Maior",
            "Canavieira",
            "Canto do Buriti",
            "Capit\u00e3o de Campos",
            "Capit\u00e3o Gerv\u00e1sio Oliveira",
            "Caracol",
            "Cara\u00fabas do Piau\u00ed",
            "Caridade do Piau\u00ed",
            "Castelo do Piau\u00ed",
            "Caxing\u00f3",
            "Cocal",
            "Cocal de Telha",
            "Cocal dos Alves",
            "Coivaras",
            "Col\u00f4nia do Gurgu\u00e9ia",
            "Col\u00f4nia do Piau\u00ed",
            "Concei\u00e7\u00e3o do Canind\u00e9",
            "Coronel Jos\u00e9 Dias",
            "Corrente",
            "Cristal\u00e2ndia do Piau\u00ed",
            "Cristino Castro",
            "Curimat\u00e1",
            "Currais",
            "Curral Novo do Piau\u00ed",
            "Curralinhos",
            "Demerval Lob\u00e3o",
            "Dirceu Arcoverde",
            "Dom Expedito Lopes",
            "Dom Inoc\u00eancio",
            "Domingos Mour\u00e3o",
            "Elesb\u00e3o Veloso",
            "Eliseu Martins",
            "Esperantina",
            "Fartura do Piau\u00ed",
            "Flores do Piau\u00ed",
            "Floresta do Piau\u00ed",
            "Floriano",
            "Francin\u00f3polis",
            "Francisco Ayres",
            "Francisco Macedo",
            "Francisco Santos",
            "Fronteiras",
            "Geminiano",
            "Gilbu\u00e9s",
            "Guadalupe",
            "Guaribas",
            "Hugo Napole\u00e3o",
            "Ilha Grande",
            "Inhuma",
            "Ipiranga do Piau\u00ed",
            "Isa\u00edas Coelho",
            "Itain\u00f3polis",
            "Itaueira",
            "Jacobina do Piau\u00ed",
            "Jaic\u00f3s",
            "Jardim do Mulato",
            "Jatob\u00e1 do Piau\u00ed",
            "Jerumenha",
            "Jo\u00e3o Costa",
            "Joaquim Pires",
            "Joca Marques",
            "Jos\u00e9 de Freitas",
            "Juazeiro do Piau\u00ed",
            "J\u00falio Borges",
            "Jurema",
            "Lagoa Alegre",
            "Lagoa de S\u00e3o Francisco",
            "Lagoa do Barro do Piau\u00ed",
            "Lagoa do Piau\u00ed",
            "Lagoa do S\u00edtio",
            "Lagoinha do Piau\u00ed",
            "Landri Sales",
            "Lu\u00eds Correia",
            "Luzil\u00e2ndia",
            "Madeiro",
            "Manoel Em\u00eddio",
            "Marcol\u00e2ndia",
            "Marcos Parente",
            "Massap\u00ea do Piau\u00ed",
            "Matias Ol\u00edmpio",
            "Miguel Alves",
            "Miguel Le\u00e3o",
            "Milton Brand\u00e3o",
            "Monsenhor Gil",
            "Monsenhor Hip\u00f3lito",
            "Monte Alegre do Piau\u00ed",
            "Morro Cabe\u00e7a no Tempo",
            "Morro do Chap\u00e9u do Piau\u00ed",
            "Murici dos Portelas",
            "Nazar\u00e9 do Piau\u00ed",
            "Naz\u00e1ria",
            "Nossa Senhora de Nazar\u00e9",
            "Nossa Senhora dos Rem\u00e9dios",
            "Nova Santa Rita",
            "Novo Oriente do Piau\u00ed",
            "Novo Santo Ant\u00f4nio",
            "Oeiras",
            "Olho D'\u00c1gua do Piau\u00ed",
            "Padre Marcos",
            "Paes Landim",
            "Paje\u00fa do Piau\u00ed",
            "Palmeira do Piau\u00ed",
            "Palmeirais",
            "Paquet\u00e1",
            "Parnagu\u00e1",
            "Parna\u00edba",
            "Passagem Franca do Piau\u00ed",
            "Patos do Piau\u00ed",
            "Pau D'Arco do Piau\u00ed",
            "Paulistana",
            "Pavussu",
            "Pedro II",
            "Pedro Laurentino",
            "Picos",
            "Pimenteiras",
            "Pio IX",
            "Piracuruca",
            "Piripiri",
            "Porto",
            "Porto Alegre do Piau\u00ed",
            "Prata do Piau\u00ed",
            "Queimada Nova",
            "Reden\u00e7\u00e3o do Gurgu\u00e9ia",
            "Regenera\u00e7\u00e3o",
            "Riacho Frio",
            "Ribeira do Piau\u00ed",
            "Ribeiro Gon\u00e7alves",
            "Rio Grande do Piau\u00ed",
            "Santa Cruz do Piau\u00ed",
            "Santa Cruz dos Milagres",
            "Santa Filomena",
            "Santa Luz",
            "Santa Rosa do Piau\u00ed",
            "Santana do Piau\u00ed",
            "Santo Ant\u00f4nio de Lisboa",
            "Santo Ant\u00f4nio dos Milagres",
            "Santo In\u00e1cio do Piau\u00ed",
            "S\u00e3o Braz do Piau\u00ed",
            "S\u00e3o F\u00e9lix do Piau\u00ed",
            "S\u00e3o Francisco de Assis do Piau\u00ed",
            "S\u00e3o Francisco do Piau\u00ed",
            "S\u00e3o Gon\u00e7alo do Gurgu\u00e9ia",
            "S\u00e3o Gon\u00e7alo do Piau\u00ed",
            "S\u00e3o Jo\u00e3o da Canabrava",
            "S\u00e3o Jo\u00e3o da Fronteira",
            "S\u00e3o Jo\u00e3o da Serra",
            "S\u00e3o Jo\u00e3o da Varjota",
            "S\u00e3o Jo\u00e3o do Arraial",
            "S\u00e3o Jo\u00e3o do Piau\u00ed",
            "S\u00e3o Jos\u00e9 do Divino",
            "S\u00e3o Jos\u00e9 do Peixe",
            "S\u00e3o Jos\u00e9 do Piau\u00ed",
            "S\u00e3o Juli\u00e3o",
            "S\u00e3o Louren\u00e7o do Piau\u00ed",
            "S\u00e3o Luis do Piau\u00ed",
            "S\u00e3o Miguel da Baixa Grande",
            "S\u00e3o Miguel do Fidalgo",
            "S\u00e3o Miguel do Tapuio",
            "S\u00e3o Pedro do Piau\u00ed",
            "S\u00e3o Raimundo Nonato",
            "Sebasti\u00e3o Barros",
            "Sebasti\u00e3o Leal",
            "Sigefredo Pacheco",
            "Sim\u00f5es",
            "Simpl\u00edcio Mendes",
            "Socorro do Piau\u00ed",
            "Sussuapara",
            "Tamboril do Piau\u00ed",
            "Tanque do Piau\u00ed",
            "Teresina",
            "Uni\u00e3o",
            "Uru\u00e7u\u00ed",
            "Valen\u00e7a do Piau\u00ed",
            "V\u00e1rzea Branca",
            "V\u00e1rzea Grande",
            "Vera Mendes",
            "Vila Nova do Piau\u00ed",
            "Wall Ferraz"
        ],
        CE: [
            "Abaiara",
            "Acarape",
            "Acara\u00fa",
            "Acopiara",
            "Aiuaba",
            "Alc\u00e2ntaras",
            "Altaneira",
            "Alto Santo",
            "Amontada",
            "Antonina do Norte",
            "Apuiar\u00e9s",
            "Aquiraz",
            "Aracati",
            "Aracoiaba",
            "Ararend\u00e1",
            "Araripe",
            "Aratuba",
            "Arneiroz",
            "Assar\u00e9",
            "Aurora",
            "Baixio",
            "Banabui\u00fa",
            "Barbalha",
            "Barreira",
            "Barro",
            "Barroquinha",
            "Baturit\u00e9",
            "Beberibe",
            "Bela Cruz",
            "Boa Viagem",
            "Brejo Santo",
            "Camocim",
            "Campos Sales",
            "Canind\u00e9",
            "Capistrano",
            "Caridade",
            "Carir\u00e9",
            "Cariria\u00e7u",
            "Cari\u00fas",
            "Carnaubal",
            "Cascavel",
            "Catarina",
            "Catunda",
            "Caucaia",
            "Cedro",
            "Chaval",
            "Chor\u00f3",
            "Chorozinho",
            "Corea\u00fa",
            "Crate\u00fas",
            "Crato",
            "Croat\u00e1",
            "Cruz",
            "Deputado Irapuan Pinheiro",
            "Erer\u00ea",
            "Eus\u00e9bio",
            "Farias Brito",
            "Forquilha",
            "Fortaleza",
            "Fortim",
            "Frecheirinha",
            "General Sampaio",
            "Gra\u00e7a",
            "Granja",
            "Granjeiro",
            "Groa\u00edras",
            "Guai\u00faba",
            "Guaraciaba do Norte",
            "Guaramiranga",
            "Hidrol\u00e2ndia",
            "Horizonte",
            "Ibaretama",
            "Ibiapina",
            "Ibicuitinga",
            "Icapu\u00ed",
            "Ic\u00f3",
            "Iguatu",
            "Independ\u00eancia",
            "Ipaporanga",
            "Ipaumirim",
            "Ipu",
            "Ipueiras",
            "Iracema",
            "Irau\u00e7uba",
            "Itai\u00e7aba",
            "Itaitinga",
            "Itapaj\u00e9",
            "Itapipoca",
            "Itapi\u00fana",
            "Itarema",
            "Itatira",
            "Jaguaretama",
            "Jaguaribara",
            "Jaguaribe",
            "Jaguaruana",
            "Jardim",
            "Jati",
            "Jijoca de Jericoacoara",
            "Juazeiro do Norte",
            "Juc\u00e1s",
            "Lavras da Mangabeira",
            "Limoeiro do Norte",
            "Madalena",
            "Maracana\u00fa",
            "Maranguape",
            "Marco",
            "Martin\u00f3pole",
            "Massap\u00ea",
            "Mauriti",
            "Meruoca",
            "Milagres",
            "Milh\u00e3",
            "Mira\u00edma",
            "Miss\u00e3o Velha",
            "Momba\u00e7a",
            "Monsenhor Tabosa",
            "Morada Nova",
            "Mora\u00fajo",
            "Morrinhos",
            "Mucambo",
            "Mulungu",
            "Nova Olinda",
            "Nova Russas",
            "Novo Oriente",
            "Ocara",
            "Or\u00f3s",
            "Pacajus",
            "Pacatuba",
            "Pacoti",
            "Pacuj\u00e1",
            "Palhano",
            "Palm\u00e1cia",
            "Paracuru",
            "Paraipaba",
            "Parambu",
            "Paramoti",
            "Pedra Branca",
            "Penaforte",
            "Pentecoste",
            "Pereiro",
            "Pindoretama",
            "Piquet Carneiro",
            "Pires Ferreira",
            "Poranga",
            "Porteiras",
            "Potengi",
            "Potiretama",
            "Quiterian\u00f3polis",
            "Quixad\u00e1",
            "Quixel\u00f4",
            "Quixeramobim",
            "Quixer\u00e9",
            "Reden\u00e7\u00e3o",
            "Reriutaba",
            "Russas",
            "Saboeiro",
            "Salitre",
            "Santa Quit\u00e9ria",
            "Santana do Acara\u00fa",
            "Santana do Cariri",
            "S\u00e3o Benedito",
            "S\u00e3o Gon\u00e7alo do Amarante",
            "S\u00e3o Jo\u00e3o do Jaguaribe",
            "S\u00e3o Lu\u00eds do Curu",
            "Senador Pompeu",
            "Senador S\u00e1",
            "Sobral",
            "Solon\u00f3pole",
            "Tabuleiro do Norte",
            "Tamboril",
            "Tarrafas",
            "Tau\u00e1",
            "Teju\u00e7uoca",
            "Tiangu\u00e1",
            "Trairi",
            "Tururu",
            "Ubajara",
            "Umari",
            "Umirim",
            "Uruburetama",
            "Uruoca",
            "Varjota",
            "V\u00e1rzea Alegre",
            "Vi\u00e7osa do Cear\u00e1"
        ],
        RN: [
            "Acari",
            "A\u00e7u",
            "Afonso Bezerra",
            "\u00c1gua Nova",
            "Alexandria",
            "Almino Afonso",
            "Alto do Rodrigues",
            "Angicos",
            "Ant\u00f4nio Martins",
            "Apodi",
            "Areia Branca",
            "Ar\u00eas",
            "Augusto Severo (Campo Grande)",
            "Ba\u00eda Formosa",
            "Bara\u00fana",
            "Barcelona",
            "Bento Fernandes",
            "Bod\u00f3",
            "Bom Jesus",
            "Brejinho",
            "Cai\u00e7ara do Norte",
            "Cai\u00e7ara do Rio do Vento",
            "Caic\u00f3",
            "Campo Redondo",
            "Canguaretama",
            "Cara\u00fabas",
            "Carna\u00faba dos Dantas",
            "Carnaubais",
            "Cear\u00e1-Mirim",
            "Cerro Cor\u00e1",
            "Coronel Ezequiel",
            "Coronel Jo\u00e3o Pessoa",
            "Cruzeta",
            "Currais Novos",
            "Doutor Severiano",
            "Encanto",
            "Equador",
            "Esp\u00edrito Santo",
            "Extremoz",
            "Felipe Guerra",
            "Fernando Pedroza",
            "Flor\u00e2nia",
            "Francisco Dantas",
            "Frutuoso Gomes",
            "Galinhos",
            "Goianinha",
            "Governador Dix-Sept Rosado",
            "Grossos",
            "Guamar\u00e9",
            "Ielmo Marinho",
            "Ipangua\u00e7u",
            "Ipueira",
            "Itaj\u00e1",
            "Ita\u00fa",
            "Ja\u00e7an\u00e3",
            "Janda\u00edra",
            "Jandu\u00eds",
            "Janu\u00e1rio Cicco (Boa Sa\u00fade)",
            "Japi",
            "Jardim de Angicos",
            "Jardim de Piranhas",
            "Jardim do Serid\u00f3",
            "Jo\u00e3o C\u00e2mara",
            "Jo\u00e3o Dias",
            "Jos\u00e9 da Penha",
            "Jucurutu",
            "Jundi\u00e1",
            "Lagoa d'Anta",
            "Lagoa de Pedras",
            "Lagoa de Velhos",
            "Lagoa Nova",
            "Lagoa Salgada",
            "Lajes",
            "Lajes Pintadas",
            "Lucr\u00e9cia",
            "Lu\u00eds Gomes",
            "Maca\u00edba",
            "Macau",
            "Major Sales",
            "Marcelino Vieira",
            "Martins",
            "Maxaranguape",
            "Messias Targino",
            "Montanhas",
            "Monte Alegre",
            "Monte das Gameleiras",
            "Mossor\u00f3",
            "Natal",
            "N\u00edsia Floresta",
            "Nova Cruz",
            "Olho-d'\u00c1gua do Borges",
            "Ouro Branco",
            "Paran\u00e1",
            "Para\u00fa",
            "Parazinho",
            "Parelhas",
            "Parnamirim",
            "Passa e Fica",
            "Passagem",
            "Patu",
            "Pau dos Ferros",
            "Pedra Grande",
            "Pedra Preta",
            "Pedro Avelino",
            "Pedro Velho",
            "Pend\u00eancias",
            "Pil\u00f5es",
            "Po\u00e7o Branco",
            "Portalegre",
            "Porto do Mangue",
            "Pureza",
            "Rafael Fernandes",
            "Rafael Godeiro",
            "Riacho da Cruz",
            "Riacho de Santana",
            "Riachuelo",
            "Rio do Fogo",
            "Rodolfo Fernandes",
            "Ruy Barbosa",
            "Santa Cruz",
            "Santa Maria",
            "Santana do Matos",
            "Santana do Serid\u00f3",
            "Santo Ant\u00f4nio",
            "S\u00e3o Bento do Norte",
            "S\u00e3o Bento do Trair\u00ed",
            "S\u00e3o Fernando",
            "S\u00e3o Francisco do Oeste",
            "S\u00e3o Gon\u00e7alo do Amarante",
            "S\u00e3o Jo\u00e3o do Sabugi",
            "S\u00e3o Jos\u00e9 de Mipibu",
            "S\u00e3o Jos\u00e9 do Campestre",
            "S\u00e3o Jos\u00e9 do Serid\u00f3",
            "S\u00e3o Miguel",
            "S\u00e3o Miguel do Gostoso",
            "S\u00e3o Paulo do Potengi",
            "S\u00e3o Pedro",
            "S\u00e3o Rafael",
            "S\u00e3o Tom\u00e9",
            "S\u00e3o Vicente",
            "Senador El\u00f3i de Souza",
            "Senador Georgino Avelino",
            "Serra Caiada",
            "Serra de S\u00e3o Bento",
            "Serra do Mel",
            "Serra Negra do Norte",
            "Serrinha",
            "Serrinha dos Pintos",
            "Severiano Melo",
            "S\u00edtio Novo",
            "Taboleiro Grande",
            "Taipu",
            "Tangar\u00e1",
            "Tenente Ananias",
            "Tenente Laurentino Cruz",
            "Tibau",
            "Tibau do Sul",
            "Timba\u00faba dos Batistas",
            "Touros",
            "Triunfo Potiguar",
            "Umarizal",
            "Upanema",
            "V\u00e1rzea",
            "Venha-Ver",
            "Vera Cruz",
            "Vi\u00e7osa",
            "Vila Flor"
        ],
        PB: [
            "\u00c1gua Branca",
            "Aguiar",
            "Alagoa Grande",
            "Alagoa Nova",
            "Alagoinha",
            "Alcantil",
            "Algod\u00e3o de Janda\u00edra",
            "Alhandra",
            "Amparo",
            "Aparecida",
            "Ara\u00e7agi",
            "Arara",
            "Araruna",
            "Areia",
            "Areia de Bara\u00fanas",
            "Areial",
            "Aroeiras",
            "Assun\u00e7\u00e3o",
            "Ba\u00eda da Trai\u00e7\u00e3o",
            "Bananeiras",
            "Bara\u00fana",
            "Barra de Santa Rosa",
            "Barra de Santana",
            "Barra de S\u00e3o Miguel",
            "Bayeux",
            "Bel\u00e9m",
            "Bel\u00e9m do Brejo do Cruz",
            "Bernardino Batista",
            "Boa Ventura",
            "Boa Vista",
            "Bom Jesus",
            "Bom Sucesso",
            "Bonito de Santa F\u00e9",
            "Boqueir\u00e3o",
            "Borborema",
            "Brejo do Cruz",
            "Brejo dos Santos",
            "Caapor\u00e3",
            "Cabaceiras",
            "Cabedelo",
            "Cachoeira dos \u00cdndios",
            "Cacimba de Areia",
            "Cacimba de Dentro",
            "Cacimbas",
            "Cai\u00e7ara",
            "Cajazeiras",
            "Cajazeirinhas",
            "Caldas Brand\u00e3o",
            "Camala\u00fa",
            "Campina Grande",
            "Capim",
            "Cara\u00fabas",
            "Carrapateira",
            "Casserengue",
            "Catingueira",
            "Catol\u00e9 do Rocha",
            "Caturit\u00e9",
            "Concei\u00e7\u00e3o",
            "Condado",
            "Conde",
            "Congo",
            "Coremas",
            "Coxixola",
            "Cruz do Esp\u00edrito Santo",
            "Cubati",
            "Cuit\u00e9",
            "Cuit\u00e9 de Mamanguape",
            "Cuitegi",
            "Curral de Cima",
            "Curral Velho",
            "Dami\u00e3o",
            "Desterro",
            "Diamante",
            "Dona In\u00eas",
            "Duas Estradas",
            "Emas",
            "Esperan\u00e7a",
            "Fagundes",
            "Frei Martinho",
            "Gado Bravo",
            "Guarabira",
            "Gurinh\u00e9m",
            "Gurj\u00e3o",
            "Ibiara",
            "Igaracy",
            "Imaculada",
            "Ing\u00e1",
            "Itabaiana",
            "Itaporanga",
            "Itapororoca",
            "Itatuba",
            "Jacara\u00fa",
            "Jeric\u00f3",
            "Jo\u00e3o Pessoa",
            "Joca Claudino",
            "Juarez T\u00e1vora",
            "Juazeirinho",
            "Junco do Serid\u00f3",
            "Juripiranga",
            "Juru",
            "Lagoa",
            "Lagoa de Dentro",
            "Lagoa Seca",
            "Lastro",
            "Livramento",
            "Logradouro",
            "Lucena",
            "M\u00e3e d'\u00c1gua",
            "Malta",
            "Mamanguape",
            "Mana\u00edra",
            "Marca\u00e7\u00e3o",
            "Mari",
            "Mariz\u00f3polis",
            "Massaranduba",
            "Mataraca",
            "Matinhas",
            "Mato Grosso",
            "Matur\u00e9ia",
            "Mogeiro",
            "Montadas",
            "Monte Horebe",
            "Monteiro",
            "Mulungu",
            "Natuba",
            "Nazarezinho",
            "Nova Floresta",
            "Nova Olinda",
            "Nova Palmeira",
            "Olho d'\u00c1gua",
            "Olivedos",
            "Ouro Velho",
            "Parari",
            "Passagem",
            "Patos",
            "Paulista",
            "Pedra Branca",
            "Pedra Lavrada",
            "Pedras de Fogo",
            "Pedro R\u00e9gis",
            "Pianc\u00f3",
            "Picu\u00ed",
            "Pilar",
            "Pil\u00f5es",
            "Pil\u00f5ezinhos",
            "Pirpirituba",
            "Pitimbu",
            "Pocinhos",
            "Po\u00e7o Dantas",
            "Po\u00e7o de Jos\u00e9 de Moura",
            "Pombal",
            "Prata",
            "Princesa Isabel",
            "Puxinan\u00e3",
            "Queimadas",
            "Quixab\u00e1",
            "Rem\u00edgio",
            "Riach\u00e3o",
            "Riach\u00e3o do Bacamarte",
            "Riach\u00e3o do Po\u00e7o",
            "Riacho de Santo Ant\u00f4nio",
            "Riacho dos Cavalos",
            "Rio Tinto",
            "Salgadinho",
            "Salgado de S\u00e3o F\u00e9lix",
            "Santa Cec\u00edlia",
            "Santa Cruz",
            "Santa Helena",
            "Santa In\u00eas",
            "Santa Luzia",
            "Santa Rita",
            "Santa Teresinha",
            "Santana de Mangueira",
            "Santana dos Garrotes",
            "Santo Andr\u00e9",
            "S\u00e3o Bentinho",
            "S\u00e3o Bento",
            "S\u00e3o Domingos",
            "S\u00e3o Domingos do Cariri",
            "S\u00e3o Francisco",
            "S\u00e3o Jo\u00e3o do Cariri",
            "S\u00e3o Jo\u00e3o do Rio do Peixe",
            "S\u00e3o Jo\u00e3o do Tigre",
            "S\u00e3o Jos\u00e9 da Lagoa Tapada",
            "S\u00e3o Jos\u00e9 de Caiana",
            "S\u00e3o Jos\u00e9 de Espinharas",
            "S\u00e3o Jos\u00e9 de Piranhas",
            "S\u00e3o Jos\u00e9 de Princesa",
            "S\u00e3o Jos\u00e9 do Bonfim",
            "S\u00e3o Jos\u00e9 do Brejo do Cruz",
            "S\u00e3o Jos\u00e9 do Sabugi",
            "S\u00e3o Jos\u00e9 dos Cordeiros",
            "S\u00e3o Jos\u00e9 dos Ramos",
            "S\u00e3o Mamede",
            "S\u00e3o Miguel de Taipu",
            "S\u00e3o Sebasti\u00e3o de Lagoa de Ro\u00e7a",
            "S\u00e3o Sebasti\u00e3o do Umbuzeiro",
            "S\u00e3o Vicente do Serid\u00f3",
            "Sap\u00e9",
            "Serra Branca",
            "Serra da Raiz",
            "Serra Grande",
            "Serra Redonda",
            "Serraria",
            "Sert\u00e3ozinho",
            "Sobrado",
            "Sol\u00e2nea",
            "Soledade",
            "Soss\u00eago",
            "Sousa",
            "Sum\u00e9",
            "Tacima",
            "Tapero\u00e1",
            "Tavares",
            "Teixeira",
            "Ten\u00f3rio",
            "Triunfo",
            "Uira\u00fana",
            "Umbuzeiro",
            "V\u00e1rzea",
            "Vieir\u00f3polis",
            "Vista Serrana",
            "Zabel\u00ea"
        ],
        PE: [
            "Abreu e Lima",
            "Afogados da Ingazeira",
            "Afr\u00e2nio",
            "Agrestina",
            "\u00c1gua Preta",
            "\u00c1guas Belas",
            "Alagoinha",
            "Alian\u00e7a",
            "Altinho",
            "Amaraji",
            "Angelim",
            "Ara\u00e7oiaba",
            "Araripina",
            "Arcoverde",
            "Barra de Guabiraba",
            "Barreiros",
            "Bel\u00e9m de Maria",
            "Bel\u00e9m do S\u00e3o Francisco",
            "Belo Jardim",
            "Bet\u00e2nia",
            "Bezerros",
            "Bodoc\u00f3",
            "Bom Conselho",
            "Bom Jardim",
            "Bonito",
            "Brej\u00e3o",
            "Brejinho",
            "Brejo da Madre de Deus",
            "Buenos Aires",
            "Bu\u00edque",
            "Cabo de Santo Agostinho",
            "Cabrob\u00f3",
            "Cachoeirinha",
            "Caet\u00e9s",
            "Cal\u00e7ado",
            "Calumbi",
            "Camaragibe",
            "Camocim de S\u00e3o F\u00e9lix",
            "Camutanga",
            "Canhotinho",
            "Capoeiras",
            "Carna\u00edba",
            "Carnaubeira da Penha",
            "Carpina",
            "Caruaru",
            "Casinhas",
            "Catende",
            "Cedro",
            "Ch\u00e3 de Alegria",
            "Ch\u00e3 Grande",
            "Condado",
            "Correntes",
            "Cort\u00eas",
            "Cumaru",
            "Cupira",
            "Cust\u00f3dia",
            "Dormentes",
            "Escada",
            "Exu",
            "Feira Nova",
            "Fernando de Noronha",
            "Ferreiros",
            "Flores",
            "Floresta",
            "Frei Miguelinho",
            "Gameleira",
            "Garanhuns",
            "Gl\u00f3ria do Goit\u00e1",
            "Goiana",
            "Granito",
            "Gravat\u00e1",
            "Iati",
            "Ibimirim",
            "Ibirajuba",
            "Igarassu",
            "Iguaracy",
            "Ilha de Itamarac\u00e1",
            "Inaj\u00e1",
            "Ingazeira",
            "Ipojuca",
            "Ipubi",
            "Itacuruba",
            "Ita\u00edba",
            "Itamb\u00e9",
            "Itapetim",
            "Itapissuma",
            "Itaquitinga",
            "Jaboat\u00e3o dos Guararapes",
            "Jaqueira",
            "Jata\u00faba",
            "Jatob\u00e1",
            "Jo\u00e3o Alfredo",
            "Joaquim Nabuco",
            "Jucati",
            "Jupi",
            "Jurema",
            "Lagoa de Itaenga",
            "Lagoa do Carro",
            "Lagoa do Ouro",
            "Lagoa dos Gatos",
            "Lagoa Grande",
            "Lajedo",
            "Limoeiro",
            "Macaparana",
            "Machados",
            "Manari",
            "Maraial",
            "Mirandiba",
            "Moreil\u00e2ndia",
            "Moreno",
            "Nazar\u00e9 da Mata",
            "Olinda",
            "Orob\u00f3",
            "Oroc\u00f3",
            "Ouricuri",
            "Palmares",
            "Palmeirina",
            "Panelas",
            "Paranatama",
            "Parnamirim",
            "Passira",
            "Paudalho",
            "Paulista",
            "Pedra",
            "Pesqueira",
            "Petrol\u00e2ndia",
            "Petrolina",
            "Po\u00e7\u00e3o",
            "Pombos",
            "Primavera",
            "Quipap\u00e1",
            "Quixaba",
            "Recife",
            "Riacho das Almas",
            "Ribeir\u00e3o",
            "Rio Formoso",
            "Sair\u00e9",
            "Salgadinho",
            "Salgueiro",
            "Salo\u00e1",
            "Sanhar\u00f3",
            "Santa Cruz",
            "Santa Cruz da Baixa Verde",
            "Santa Cruz do Capibaribe",
            "Santa Filomena",
            "Santa Maria da Boa Vista",
            "Santa Maria do Cambuc\u00e1",
            "Santa Terezinha",
            "S\u00e3o Benedito do Sul",
            "S\u00e3o Bento do Una",
            "S\u00e3o Caetano",
            "S\u00e3o Jo\u00e3o",
            "S\u00e3o Joaquim do Monte",
            "S\u00e3o Jos\u00e9 da Coroa Grande",
            "S\u00e3o Jos\u00e9 do Belmonte",
            "S\u00e3o Jos\u00e9 do Egito",
            "S\u00e3o Louren\u00e7o da Mata",
            "S\u00e3o Vicente Ferrer",
            "Serra Talhada",
            "Serrita",
            "Sert\u00e2nia",
            "Sirinha\u00e9m",
            "Solid\u00e3o",
            "Surubim",
            "Tabira",
            "Tacaimb\u00f3",
            "Tacaratu",
            "Tamandar\u00e9",
            "Taquaritinga do Norte",
            "Terezinha",
            "Terra Nova",
            "Timba\u00faba",
            "Toritama",
            "Tracunha\u00e9m",
            "Trindade",
            "Triunfo",
            "Tupanatinga",
            "Tuparetama",
            "Venturosa",
            "Verdejante",
            "Vertente do L\u00e9rio",
            "Vertentes",
            "Vic\u00eancia",
            "Vit\u00f3ria de Santo Ant\u00e3o",
            "Xex\u00e9u"
        ],
        AL: [
            "\u00c1gua Branca",
            "Anadia",
            "Arapiraca",
            "Atalaia",
            "Barra de Santo Ant\u00f4nio",
            "Barra de S\u00e3o Miguel",
            "Batalha",
            "Bel\u00e9m",
            "Belo Monte",
            "Boca da Mata",
            "Branquinha",
            "Cacimbinhas",
            "Cajueiro",
            "Campestre",
            "Campo Alegre",
            "Campo Grande",
            "Canapi",
            "Capela",
            "Carneiros",
            "Ch\u00e3 Preta",
            "Coit\u00e9 do N\u00f3ia",
            "Col\u00f4nia Leopoldina",
            "Coqueiro Seco",
            "Coruripe",
            "Cra\u00edbas",
            "Delmiro Gouveia",
            "Dois Riachos",
            "Estrela de Alagoas",
            "Feira Grande",
            "Feliz Deserto",
            "Flexeiras",
            "Girau do Ponciano",
            "Ibateguara",
            "Igaci",
            "Igreja Nova",
            "Inhapi",
            "Jacar\u00e9 dos Homens",
            "Jacu\u00edpe",
            "Japaratinga",
            "Jaramataia",
            "Jequi\u00e1 da Praia",
            "Joaquim Gomes",
            "Jundi\u00e1",
            "Junqueiro",
            "Lagoa da Canoa",
            "Limoeiro de Anadia",
            "Macei\u00f3",
            "Major Isidoro",
            "Mar Vermelho",
            "Maragogi",
            "Maravilha",
            "Marechal Deodoro",
            "Maribondo",
            "Mata Grande",
            "Matriz de Camaragibe",
            "Messias",
            "Minador do Negr\u00e3o",
            "Monteir\u00f3polis",
            "Murici",
            "Novo Lino",
            "Olho d'\u00c1gua das Flores",
            "Olho d'\u00c1gua do Casado",
            "Olho d'\u00c1gua Grande",
            "Oliven\u00e7a",
            "Ouro Branco",
            "Palestina",
            "Palmeira dos \u00cdndios",
            "P\u00e3o de A\u00e7\u00facar",
            "Pariconha",
            "Paripueira",
            "Passo de Camaragibe",
            "Paulo Jacinto",
            "Penedo",
            "Pia\u00e7abu\u00e7u",
            "Pilar",
            "Pindoba",
            "Piranhas",
            "Po\u00e7o das Trincheiras",
            "Porto Calvo",
            "Porto de Pedras",
            "Porto Real do Col\u00e9gio",
            "Quebrangulo",
            "Rio Largo",
            "Roteiro",
            "Santa Luzia do Norte",
            "Santana do Ipanema",
            "Santana do Munda\u00fa",
            "S\u00e3o Br\u00e1s",
            "S\u00e3o Jos\u00e9 da Laje",
            "S\u00e3o Jos\u00e9 da Tapera",
            "S\u00e3o Lu\u00eds do Quitunde",
            "S\u00e3o Miguel dos Campos",
            "S\u00e3o Miguel dos Milagres",
            "S\u00e3o Sebasti\u00e3o",
            "Satuba",
            "Senador Rui Palmeira",
            "Tanque d'Arca",
            "Taquarana",
            "Teot\u00f4nio Vilela",
            "Traipu",
            "Uni\u00e3o dos Palmares",
            "Vi\u00e7osa"
        ],
        SE: [
            "Amparo de S\u00e3o Francisco",
            "Aquidab\u00e3",
            "Aracaju",
            "Arau\u00e1",
            "Areia Branca",
            "Barra dos Coqueiros",
            "Boquim",
            "Brejo Grande",
            "Campo do Brito",
            "Canhoba",
            "Canind\u00e9 de S\u00e3o Francisco",
            "Capela",
            "Carira",
            "Carm\u00f3polis",
            "Cedro de S\u00e3o Jo\u00e3o",
            "Cristin\u00e1polis",
            "Cumbe",
            "Divina Pastora",
            "Est\u00e2ncia",
            "Feira Nova",
            "Frei Paulo",
            "Gararu",
            "General Maynard",
            "Gracho Cardoso",
            "Ilha das Flores",
            "Indiaroba",
            "Itabaiana",
            "Itabaianinha",
            "Itabi",
            "Itaporanga d'Ajuda",
            "Japaratuba",
            "Japoat\u00e3",
            "Lagarto",
            "Laranjeiras",
            "Macambira",
            "Malhada dos Bois",
            "Malhador",
            "Maruim",
            "Moita Bonita",
            "Monte Alegre de Sergipe",
            "Muribeca",
            "Ne\u00f3polis",
            "Nossa Senhora Aparecida",
            "Nossa Senhora da Gl\u00f3ria",
            "Nossa Senhora das Dores",
            "Nossa Senhora de Lourdes",
            "Nossa Senhora do Socorro",
            "Pacatuba",
            "Pedra Mole",
            "Pedrinhas",
            "Pinh\u00e3o",
            "Pirambu",
            "Po\u00e7o Redondo",
            "Po\u00e7o Verde",
            "Porto da Folha",
            "Propri\u00e1",
            "Riach\u00e3o do Dantas",
            "Riachuelo",
            "Ribeir\u00f3polis",
            "Ros\u00e1rio do Catete",
            "Salgado",
            "Santa Luzia do Itanhy",
            "Santa Rosa de Lima",
            "Santana do S\u00e3o Francisco",
            "Santo Amaro das Brotas",
            "S\u00e3o Crist\u00f3v\u00e3o",
            "S\u00e3o Domingos",
            "S\u00e3o Francisco",
            "S\u00e3o Miguel do Aleixo",
            "Sim\u00e3o Dias",
            "Siriri",
            "Telha",
            "Tobias Barreto",
            "Tomar do Geru",
            "Umba\u00faba"
        ],
        BA: [
            "Aba\u00edra",
            "Abar\u00e9",
            "Acajutiba",
            "Adustina",
            "\u00c1gua Fria",
            "Aiquara",
            "Alagoinhas",
            "Alcoba\u00e7a",
            "Almadina",
            "Amargosa",
            "Am\u00e9lia Rodrigues",
            "Am\u00e9rica Dourada",
            "Anag\u00e9",
            "Andara\u00ed",
            "Andorinha",
            "Angical",
            "Anguera",
            "Antas",
            "Ant\u00f4nio Cardoso",
            "Ant\u00f4nio Gon\u00e7alves",
            "Apor\u00e1",
            "Apuarema",
            "Ara\u00e7as",
            "Aracatu",
            "Araci",
            "Aramari",
            "Arataca",
            "Aratu\u00edpe",
            "Aurelino Leal",
            "Baian\u00f3polis",
            "Baixa Grande",
            "Banza\u00ea",
            "Barra",
            "Barra da Estiva",
            "Barra do Cho\u00e7a",
            "Barra do Mendes",
            "Barra do Rocha",
            "Barreiras",
            "Barro Alto",
            "Barro Preto",
            "Barrocas",
            "Belmonte",
            "Belo Campo",
            "Biritinga",
            "Boa Nova",
            "Boa Vista do Tupim",
            "Bom Jesus da Lapa",
            "Bom Jesus da Serra",
            "Boninal",
            "Bonito",
            "Boquira",
            "Botupor\u00e3",
            "Brej\u00f5es",
            "Brejol\u00e2ndia",
            "Brotas de Maca\u00fabas",
            "Brumado",
            "Buerarema",
            "Buritirama",
            "Caatiba",
            "Cabaceiras do Paragua\u00e7u",
            "Cachoeira",
            "Cacul\u00e9",
            "Ca\u00e9m",
            "Caetanos",
            "Caetit\u00e9",
            "Cafarnaum",
            "Cairu",
            "Caldeir\u00e3o Grande",
            "Camacan",
            "Cama\u00e7ari",
            "Camamu",
            "Campo Alegre de Lourdes",
            "Campo Formoso",
            "Can\u00e1polis",
            "Canarana",
            "Canavieiras",
            "Candeal",
            "Candeias",
            "Candiba",
            "C\u00e2ndido Sales",
            "Cansan\u00e7\u00e3o",
            "Canudos",
            "Capela do Alto Alegre",
            "Capim Grosso",
            "Cara\u00edbas",
            "Caravelas",
            "Cardeal da Silva",
            "Carinhanha",
            "Casa Nova",
            "Castro Alves",
            "Catol\u00e2ndia",
            "Catu",
            "Caturama",
            "Central",
            "Chorroch\u00f3",
            "C\u00edcero Dantas",
            "Cip\u00f3",
            "Coaraci",
            "Cocos",
            "Concei\u00e7\u00e3o da Feira",
            "Concei\u00e7\u00e3o do Almeida",
            "Concei\u00e7\u00e3o do Coit\u00e9",
            "Concei\u00e7\u00e3o do Jacu\u00edpe",
            "Conde",
            "Conde\u00faba",
            "Contendas do Sincor\u00e1",
            "Cora\u00e7\u00e3o de Maria",
            "Cordeiros",
            "Coribe",
            "Coronel Jo\u00e3o S\u00e1",
            "Correntina",
            "Cotegipe",
            "Cravol\u00e2ndia",
            "Cris\u00f3polis",
            "Crist\u00f3polis",
            "Cruz das Almas",
            "Cura\u00e7\u00e1",
            "D\u00e1rio Meira",
            "Dias d'\u00c1vila",
            "Dom Bas\u00edlio",
            "Dom Macedo Costa",
            "El\u00edsio Medrado",
            "Encruzilhada",
            "Entre Rios",
            "\u00c9rico Cardoso",
            "Esplanada",
            "Euclides da Cunha",
            "Eun\u00e1polis",
            "F\u00e1tima",
            "Feira da Mata",
            "Feira de Santana",
            "Filad\u00e9lfia",
            "Firmino Alves",
            "Floresta Azul",
            "Formosa do Rio Preto",
            "Gandu",
            "Gavi\u00e3o",
            "Gentio do Ouro",
            "Gl\u00f3ria",
            "Gongogi",
            "Governador Mangabeira",
            "Guajeru",
            "Guanambi",
            "Guaratinga",
            "Heli\u00f3polis",
            "Ia\u00e7u",
            "Ibiassuc\u00ea",
            "Ibicara\u00ed",
            "Ibicoara",
            "Ibicu\u00ed",
            "Ibipeba",
            "Ibipitanga",
            "Ibiquera",
            "Ibirapitanga",
            "Ibirapu\u00e3",
            "Ibirataia",
            "Ibitiara",
            "Ibitit\u00e1",
            "Ibotirama",
            "Ichu",
            "Igapor\u00e3",
            "Igrapi\u00fana",
            "Igua\u00ed",
            "Ilh\u00e9us",
            "Inhambupe",
            "Ipecaet\u00e1",
            "Ipia\u00fa",
            "Ipir\u00e1",
            "Ipupiara",
            "Irajuba",
            "Iramaia",
            "Iraquara",
            "Irar\u00e1",
            "Irec\u00ea",
            "Itabela",
            "Itaberaba",
            "Itabuna",
            "Itacar\u00e9",
            "Itaet\u00e9",
            "Itagi",
            "Itagib\u00e1",
            "Itagimirim",
            "Itagua\u00e7u da Bahia",
            "Itaju do Col\u00f4nia",
            "Itaju\u00edpe",
            "Itamaraju",
            "Itamari",
            "Itamb\u00e9",
            "Itanagra",
            "Itanh\u00e9m",
            "Itaparica",
            "Itap\u00e9",
            "Itapebi",
            "Itapetinga",
            "Itapicuru",
            "Itapitanga",
            "Itaquara",
            "Itarantim",
            "Itatim",
            "Itiru\u00e7u",
            "Iti\u00faba",
            "Itoror\u00f3",
            "Itua\u00e7u",
            "Ituber\u00e1",
            "Iui\u00fa",
            "Jaborandi",
            "Jacaraci",
            "Jacobina",
            "Jaguaquara",
            "Jaguarari",
            "Jaguaripe",
            "Janda\u00edra",
            "Jequi\u00e9",
            "Jeremoabo",
            "Jiquiri\u00e7\u00e1",
            "Jita\u00fana",
            "Jo\u00e3o Dourado",
            "Juazeiro",
            "Jucuru\u00e7u",
            "Jussara",
            "Jussari",
            "Jussiape",
            "Lafaiete Coutinho",
            "Lagoa Real",
            "Laje",
            "Lajed\u00e3o",
            "Lajedinho",
            "Lajedo do Tabocal",
            "Lamar\u00e3o",
            "Lap\u00e3o",
            "Lauro de Freitas",
            "Len\u00e7\u00f3is",
            "Lic\u00ednio de Almeida",
            "Livramento de Nossa Senhora",
            "Lu\u00eds Eduardo Magalh\u00e3es",
            "Macajuba",
            "Macarani",
            "Maca\u00fabas",
            "Macurur\u00e9",
            "Madre de Deus",
            "Maetinga",
            "Maiquinique",
            "Mairi",
            "Malhada",
            "Malhada de Pedras",
            "Manoel Vitorino",
            "Mansid\u00e3o",
            "Marac\u00e1s",
            "Maragogipe",
            "Mara\u00fa",
            "Marcion\u00edlio Souza",
            "Mascote",
            "Mata de S\u00e3o Jo\u00e3o",
            "Matina",
            "Medeiros Neto",
            "Miguel Calmon",
            "Milagres",
            "Mirangaba",
            "Mirante",
            "Monte Santo",
            "Morpar\u00e1",
            "Morro do Chap\u00e9u",
            "Mortugaba",
            "Mucug\u00ea",
            "Mucuri",
            "Mulungu do Morro",
            "Mundo Novo",
            "Muniz Ferreira",
            "Muqu\u00e9m de S\u00e3o Francisco",
            "Muritiba",
            "Mutu\u00edpe",
            "Nazar\u00e9",
            "Nilo Pe\u00e7anha",
            "Nordestina",
            "Nova Cana\u00e3",
            "Nova F\u00e1tima",
            "Nova Ibi\u00e1",
            "Nova Itarana",
            "Nova Reden\u00e7\u00e3o",
            "Nova Soure",
            "Nova Vi\u00e7osa",
            "Novo Horizonte",
            "Novo Triunfo",
            "Olindina",
            "Oliveira dos Brejinhos",
            "Ouri\u00e7angas",
            "Ourol\u00e2ndia",
            "Palmas de Monte Alto",
            "Palmeiras",
            "Paramirim",
            "Paratinga",
            "Paripiranga",
            "Pau Brasil",
            "Paulo Afonso",
            "P\u00e9 de Serra",
            "Pedr\u00e3o",
            "Pedro Alexandre",
            "Piat\u00e3",
            "Pil\u00e3o Arcado",
            "Pinda\u00ed",
            "Pindoba\u00e7u",
            "Pintadas",
            "Pira\u00ed do Norte",
            "Pirip\u00e1",
            "Piritiba",
            "Planaltino",
            "Planalto",
            "Po\u00e7\u00f5es",
            "Pojuca",
            "Ponto Novo",
            "Porto Seguro",
            "Potiragu\u00e1",
            "Prado",
            "Presidente Dutra",
            "Presidente J\u00e2nio Quadros",
            "Presidente Tancredo Neves",
            "Queimadas",
            "Quijingue",
            "Quixabeira",
            "Rafael Jambeiro",
            "Remanso",
            "Retirol\u00e2ndia",
            "Riach\u00e3o das Neves",
            "Riach\u00e3o do Jacu\u00edpe",
            "Riacho de Santana",
            "Ribeira do Amparo",
            "Ribeira do Pombal",
            "Ribeir\u00e3o do Largo",
            "Rio de Contas",
            "Rio do Ant\u00f4nio",
            "Rio do Pires",
            "Rio Real",
            "Rodelas",
            "Ruy Barbosa",
            "Salinas da Margarida",
            "Salvador",
            "Santa B\u00e1rbara",
            "Santa Br\u00edgida",
            "Santa Cruz Cabr\u00e1lia",
            "Santa Cruz da Vit\u00f3ria",
            "Santa In\u00eas",
            "Santa Luzia",
            "Santa Maria da Vit\u00f3ria",
            "Santa Rita de C\u00e1ssia",
            "Santa Teresinha",
            "Santaluz",
            "Santana",
            "Santan\u00f3polis",
            "Santo Amaro",
            "Santo Ant\u00f4nio de Jesus",
            "Santo Est\u00eav\u00e3o",
            "S\u00e3o Desid\u00e9rio",
            "S\u00e3o Domingos",
            "S\u00e3o Felipe",
            "S\u00e3o F\u00e9lix",
            "S\u00e3o F\u00e9lix do Coribe",
            "S\u00e3o Francisco do Conde",
            "S\u00e3o Gabriel",
            "S\u00e3o Gon\u00e7alo dos Campos",
            "S\u00e3o Jos\u00e9 da Vit\u00f3ria",
            "S\u00e3o Jos\u00e9 do Jacu\u00edpe",
            "S\u00e3o Miguel das Matas",
            "S\u00e3o Sebasti\u00e3o do Pass\u00e9",
            "Sapea\u00e7u",
            "S\u00e1tiro Dias",
            "Saubara",
            "Sa\u00fade",
            "Seabra",
            "Sebasti\u00e3o Laranjeiras",
            "Senhor do Bonfim",
            "Sento S\u00e9",
            "Serra do Ramalho",
            "Serra Dourada",
            "Serra Preta",
            "Serrinha",
            "Serrol\u00e2ndia",
            "Sim\u00f5es Filho",
            "S\u00edtio do Mato",
            "S\u00edtio do Quinto",
            "Sobradinho",
            "Souto Soares",
            "Tabocas do Brejo Velho",
            "Tanha\u00e7u",
            "Tanque Novo",
            "Tanquinho",
            "Tapero\u00e1",
            "Tapiramut\u00e1",
            "Teixeira de Freitas",
            "Teodoro Sampaio",
            "Teofil\u00e2ndia",
            "Teol\u00e2ndia",
            "Terra Nova",
            "Tremedal",
            "Tucano",
            "Uau\u00e1",
            "Uba\u00edra",
            "Ubaitaba",
            "Ubat\u00e3",
            "Uiba\u00ed",
            "Umburanas",
            "Una",
            "Urandi",
            "Uru\u00e7uca",
            "Utinga",
            "Valen\u00e7a",
            "Valente",
            "V\u00e1rzea da Ro\u00e7a",
            "V\u00e1rzea do Po\u00e7o",
            "V\u00e1rzea Nova",
            "Varzedo",
            "Vera Cruz",
            "Vereda",
            "Vit\u00f3ria da Conquista",
            "Wagner",
            "Wanderley",
            "Wenceslau Guimar\u00e3es",
            "Xique-Xique"
        ],
        MG: [
            "Abadia dos Dourados",
            "Abaet\u00e9",
            "Abre Campo",
            "Acaiaca",
            "A\u00e7ucena",
            "\u00c1gua Boa",
            "\u00c1gua Comprida",
            "Aguanil",
            "\u00c1guas Formosas",
            "\u00c1guas Vermelhas",
            "Aimor\u00e9s",
            "Aiuruoca",
            "Alagoa",
            "Albertina",
            "Al\u00e9m Para\u00edba",
            "Alfenas",
            "Alfredo Vasconcelos",
            "Almenara",
            "Alpercata",
            "Alpin\u00f3polis",
            "Alterosa",
            "Alto Capara\u00f3",
            "Alto Jequitib\u00e1",
            "Alto Rio Doce",
            "Alvarenga",
            "Alvin\u00f3polis",
            "Alvorada de Minas",
            "Amparo do Serra",
            "Andradas",
            "Andrel\u00e2ndia",
            "Angel\u00e2ndia",
            "Ant\u00f4nio Carlos",
            "Ant\u00f4nio Dias",
            "Ant\u00f4nio Prado de Minas",
            "Ara\u00e7a\u00ed",
            "Aracitaba",
            "Ara\u00e7ua\u00ed",
            "Araguari",
            "Arantina",
            "Araponga",
            "Arapor\u00e3",
            "Arapu\u00e1",
            "Ara\u00fajos",
            "Arax\u00e1",
            "Arceburgo",
            "Arcos",
            "Areado",
            "Argirita",
            "Aricanduva",
            "Arinos",
            "Astolfo Dutra",
            "Atal\u00e9ia",
            "Augusto de Lima",
            "Baependi",
            "Baldim",
            "Bambu\u00ed",
            "Bandeira",
            "Bandeira do Sul",
            "Bar\u00e3o de Cocais",
            "Bar\u00e3o de Monte Alto",
            "Barbacena",
            "Barra Longa",
            "Barroso",
            "Bela Vista de Minas",
            "Belmiro Braga",
            "Belo Horizonte",
            "Belo Oriente",
            "Belo Vale",
            "Berilo",
            "Berizal",
            "Bert\u00f3polis",
            "Betim",
            "Bias Fortes",
            "Bicas",
            "Biquinhas",
            "Boa Esperan\u00e7a",
            "Bocaina de Minas",
            "Bocai\u00fava",
            "Bom Despacho",
            "Bom Jardim de Minas",
            "Bom Jesus da Penha",
            "Bom Jesus do Amparo",
            "Bom Jesus do Galho",
            "Bom Repouso",
            "Bom Sucesso",
            "Bonfim",
            "Bonfin\u00f3polis de Minas",
            "Bonito de Minas",
            "Borda da Mata",
            "Botelhos",
            "Botumirim",
            "Br\u00e1s Pires",
            "Brasil\u00e2ndia de Minas",
            "Bras\u00edlia de Minas",
            "Bra\u00fanas",
            "Braz\u00f3polis",
            "Brumadinho",
            "Bueno Brand\u00e3o",
            "Buen\u00f3polis",
            "Bugre",
            "Buritis",
            "Buritizeiro",
            "Cabeceira Grande",
            "Cabo Verde",
            "Cachoeira da Prata",
            "Cachoeira de Minas",
            "Cachoeira de Paje\u00fa",
            "Cachoeira Dourada",
            "Caetan\u00f3polis",
            "Caet\u00e9",
            "Caiana",
            "Cajuri",
            "Caldas",
            "Camacho",
            "Camanducaia",
            "Cambu\u00ed",
            "Cambuquira",
            "Campan\u00e1rio",
            "Campanha",
            "Campestre",
            "Campina Verde",
            "Campo Azul",
            "Campo Belo",
            "Campo do Meio",
            "Campo Florido",
            "Campos Altos",
            "Campos Gerais",
            "Cana Verde",
            "Cana\u00e3",
            "Can\u00e1polis",
            "Candeias",
            "Cantagalo",
            "Capara\u00f3",
            "Capela Nova",
            "Capelinha",
            "Capetinga",
            "Capim Branco",
            "Capin\u00f3polis",
            "Capit\u00e3o Andrade",
            "Capit\u00e3o En\u00e9as",
            "Capit\u00f3lio",
            "Caputira",
            "Cara\u00ed",
            "Carana\u00edba",
            "Caranda\u00ed",
            "Carangola",
            "Caratinga",
            "Carbonita",
            "Carea\u00e7u",
            "Carlos Chagas",
            "Carm\u00e9sia",
            "Carmo da Cachoeira",
            "Carmo da Mata",
            "Carmo de Minas",
            "Carmo do Cajuru",
            "Carmo do Parana\u00edba",
            "Carmo do Rio Claro",
            "Carm\u00f3polis de Minas",
            "Carneirinho",
            "Carrancas",
            "Carvalh\u00f3polis",
            "Carvalhos",
            "Casa Grande",
            "Cascalho Rico",
            "C\u00e1ssia",
            "Cataguases",
            "Catas Altas",
            "Catas Altas da Noruega",
            "Catuji",
            "Catuti",
            "Caxambu",
            "Cedro do Abaet\u00e9",
            "Central de Minas",
            "Centralina",
            "Ch\u00e1cara",
            "Chal\u00e9",
            "Chapada do Norte",
            "Chapada Ga\u00facha",
            "Chiador",
            "Cipot\u00e2nea",
            "Claraval",
            "Claro dos Po\u00e7\u00f5es",
            "Cl\u00e1udio",
            "Coimbra",
            "Coluna",
            "Comendador Gomes",
            "Comercinho",
            "Concei\u00e7\u00e3o da Aparecida",
            "Concei\u00e7\u00e3o da Barra de Minas",
            "Concei\u00e7\u00e3o das Alagoas",
            "Concei\u00e7\u00e3o das Pedras",
            "Concei\u00e7\u00e3o de Ipanema",
            "Concei\u00e7\u00e3o do Mato Dentro",
            "Concei\u00e7\u00e3o do Par\u00e1",
            "Concei\u00e7\u00e3o do Rio Verde",
            "Concei\u00e7\u00e3o dos Ouros",
            "C\u00f4nego Marinho",
            "Confins",
            "Congonhal",
            "Congonhas",
            "Congonhas do Norte",
            "Conquista",
            "Conselheiro Lafaiete",
            "Conselheiro Pena",
            "Consola\u00e7\u00e3o",
            "Contagem",
            "Coqueiral",
            "Cora\u00e7\u00e3o de Jesus",
            "Cordisburgo",
            "Cordisl\u00e2ndia",
            "Corinto",
            "Coroaci",
            "Coromandel",
            "Coronel Fabriciano",
            "Coronel Murta",
            "Coronel Pacheco",
            "Coronel Xavier Chaves",
            "C\u00f3rrego Danta",
            "C\u00f3rrego do Bom Jesus",
            "C\u00f3rrego Fundo",
            "C\u00f3rrego Novo",
            "Couto de Magalh\u00e3es de Minas",
            "Cris\u00f3lita",
            "Cristais",
            "Crist\u00e1lia",
            "Cristiano Otoni",
            "Cristina",
            "Crucil\u00e2ndia",
            "Cruzeiro da Fortaleza",
            "Cruz\u00edlia",
            "Cuparaque",
            "Curral de Dentro",
            "Curvelo",
            "Datas",
            "Delfim Moreira",
            "Delfin\u00f3polis",
            "Delta",
            "Descoberto",
            "Desterro de Entre Rios",
            "Desterro do Melo",
            "Diamantina",
            "Diogo de Vasconcelos",
            "Dion\u00edsio",
            "Divin\u00e9sia",
            "Divino",
            "Divino das Laranjeiras",
            "Divinol\u00e2ndia de Minas",
            "Divin\u00f3polis",
            "Divisa Alegre",
            "Divisa Nova",
            "Divis\u00f3polis",
            "Dom Bosco",
            "Dom Cavati",
            "Dom Joaquim",
            "Dom Silv\u00e9rio",
            "Dom Vi\u00e7oso",
            "Dona Eus\u00e9bia",
            "Dores de Campos",
            "Dores de Guanh\u00e3es",
            "Dores do Indai\u00e1",
            "Dores do Turvo",
            "Dores\u00f3polis",
            "Douradoquara",
            "Durand\u00e9",
            "El\u00f3i Mendes",
            "Engenheiro Caldas",
            "Engenheiro Navarro",
            "Entre Folhas",
            "Entre Rios de Minas",
            "Erv\u00e1lia",
            "Esmeraldas",
            "Espera Feliz",
            "Espinosa",
            "Esp\u00edrito Santo do Dourado",
            "Estiva",
            "Estrela Dalva",
            "Estrela do Indai\u00e1",
            "Estrela do Sul",
            "Eugen\u00f3polis",
            "Ewbank da C\u00e2mara",
            "Extrema",
            "Fama",
            "Faria Lemos",
            "Fel\u00edcio dos Santos",
            "Felisburgo",
            "Felixl\u00e2ndia",
            "Fernandes Tourinho",
            "Ferros",
            "Fervedouro",
            "Florestal",
            "Formiga",
            "Formoso",
            "Fortaleza de Minas",
            "Fortuna de Minas",
            "Francisco Badar\u00f3",
            "Francisco Dumont",
            "Francisco S\u00e1",
            "Francisc\u00f3polis",
            "Frei Gaspar",
            "Frei Inoc\u00eancio",
            "Frei Lagonegro",
            "Fronteira",
            "Fronteira dos Vales",
            "Fruta de Leite",
            "Frutal",
            "Funil\u00e2ndia",
            "Galil\u00e9ia",
            "Gameleiras",
            "Glaucil\u00e2ndia",
            "Goiabeira",
            "Goian\u00e1",
            "Gon\u00e7alves",
            "Gonzaga",
            "Gouveia",
            "Governador Valadares",
            "Gr\u00e3o Mogol",
            "Grupiara",
            "Guanh\u00e3es",
            "Guap\u00e9",
            "Guaraciaba",
            "Guaraciama",
            "Guaran\u00e9sia",
            "Guarani",
            "Guarar\u00e1",
            "Guarda-Mor",
            "Guaxup\u00e9",
            "Guidoval",
            "Guimar\u00e2nia",
            "Guiricema",
            "Gurinhat\u00e3",
            "Heliodora",
            "Iapu",
            "Ibertioga",
            "Ibi\u00e1",
            "Ibia\u00ed",
            "Ibiracatu",
            "Ibiraci",
            "Ibirit\u00e9",
            "Ibiti\u00fara de Minas",
            "Ibituruna",
            "Icara\u00ed de Minas",
            "Igarap\u00e9",
            "Igaratinga",
            "Iguatama",
            "Ijaci",
            "Ilic\u00ednea",
            "Imb\u00e9 de Minas",
            "Inconfidentes",
            "Indaiabira",
            "Indian\u00f3polis",
            "Inga\u00ed",
            "Inhapim",
            "Inha\u00fama",
            "Inimutaba",
            "Ipaba",
            "Ipanema",
            "Ipatinga",
            "Ipia\u00e7u",
            "Ipui\u00fana",
            "Ira\u00ed de Minas",
            "Itabira",
            "Itabirinha",
            "Itabirito",
            "Itacambira",
            "Itacarambi",
            "Itaguara",
            "Itaip\u00e9",
            "Itajub\u00e1",
            "Itamarandiba",
            "Itamarati de Minas",
            "Itambacuri",
            "Itamb\u00e9 do Mato Dentro",
            "Itamogi",
            "Itamonte",
            "Itanhandu",
            "Itanhomi",
            "Itaobim",
            "Itapagipe",
            "Itapecerica",
            "Itapeva",
            "Itatiaiu\u00e7u",
            "Ita\u00fa de Minas",
            "Ita\u00fana",
            "Itaverava",
            "Itinga",
            "Itueta",
            "Ituiutaba",
            "Itumirim",
            "Iturama",
            "Itutinga",
            "Jaboticatubas",
            "Jacinto",
            "Jacu\u00ed",
            "Jacutinga",
            "Jaguara\u00e7u",
            "Ja\u00edba",
            "Jampruca",
            "Jana\u00faba",
            "Janu\u00e1ria",
            "Japara\u00edba",
            "Japonvar",
            "Jeceaba",
            "Jenipapo de Minas",
            "Jequeri",
            "Jequita\u00ed",
            "Jequitib\u00e1",
            "Jequitinhonha",
            "Jesu\u00e2nia",
            "Joa\u00edma",
            "Joan\u00e9sia",
            "Jo\u00e3o Monlevade",
            "Jo\u00e3o Pinheiro",
            "Joaquim Fel\u00edcio",
            "Jord\u00e2nia",
            "Jos\u00e9 Gon\u00e7alves de Minas",
            "Jos\u00e9 Raydan",
            "Josen\u00f3polis",
            "Juatuba",
            "Juiz de Fora",
            "Juramento",
            "Juruaia",
            "Juven\u00edlia",
            "Ladainha",
            "Lagamar",
            "Lagoa da Prata",
            "Lagoa dos Patos",
            "Lagoa Dourada",
            "Lagoa Formosa",
            "Lagoa Grande",
            "Lagoa Santa",
            "Lajinha",
            "Lambari",
            "Lamim",
            "Laranjal",
            "Lassance",
            "Lavras",
            "Leandro Ferreira",
            "Leme do Prado",
            "Leopoldina",
            "Liberdade",
            "Lima Duarte",
            "Limeira do Oeste",
            "Lontra",
            "Luisburgo",
            "Luisl\u00e2ndia",
            "Lumin\u00e1rias",
            "Luz",
            "Machacalis",
            "Machado",
            "Madre de Deus de Minas",
            "Malacacheta",
            "Mamonas",
            "Manga",
            "Manhua\u00e7u",
            "Manhumirim",
            "Mantena",
            "Mar de Espanha",
            "Maravilhas",
            "Maria da F\u00e9",
            "Mariana",
            "Marilac",
            "M\u00e1rio Campos",
            "Marip\u00e1 de Minas",
            "Marli\u00e9ria",
            "Marmel\u00f3polis",
            "Martinho Campos",
            "Martins Soares",
            "Mata Verde",
            "Materl\u00e2ndia",
            "Mateus Leme",
            "Mathias Lobato",
            "Matias Barbosa",
            "Matias Cardoso",
            "Matip\u00f3",
            "Mato Verde",
            "Matozinhos",
            "Matutina",
            "Medeiros",
            "Medina",
            "Mendes Pimentel",
            "Merc\u00eas",
            "Mesquita",
            "Minas Novas",
            "Minduri",
            "Mirabela",
            "Miradouro",
            "Mira\u00ed",
            "Mirav\u00e2nia",
            "Moeda",
            "Moema",
            "Monjolos",
            "Monsenhor Paulo",
            "Montalv\u00e2nia",
            "Monte Alegre de Minas",
            "Monte Azul",
            "Monte Belo",
            "Monte Carmelo",
            "Monte Formoso",
            "Monte Santo de Minas",
            "Monte Si\u00e3o",
            "Montes Claros",
            "Montezuma",
            "Morada Nova de Minas",
            "Morro da Gar\u00e7a",
            "Morro do Pilar",
            "Munhoz",
            "Muria\u00e9",
            "Mutum",
            "Muzambinho",
            "Nacip Raydan",
            "Nanuque",
            "Naque",
            "Natal\u00e2ndia",
            "Nat\u00e9rcia",
            "Nazareno",
            "Nepomuceno",
            "Ninheira",
            "Nova Bel\u00e9m",
            "Nova Era",
            "Nova Lima",
            "Nova M\u00f3dica",
            "Nova Ponte",
            "Nova Porteirinha",
            "Nova Resende",
            "Nova Serrana",
            "Nova Uni\u00e3o",
            "Novo Cruzeiro",
            "Novo Oriente de Minas",
            "Novorizonte",
            "Olaria",
            "Olhos d'\u00c1gua",
            "Ol\u00edmpio Noronha",
            "Oliveira",
            "Oliveira Fortes",
            "On\u00e7a de Pitangui",
            "Orat\u00f3rios",
            "Oriz\u00e2nia",
            "Ouro Branco",
            "Ouro Fino",
            "Ouro Preto",
            "Ouro Verde de Minas",
            "Padre Carvalho",
            "Padre Para\u00edso",
            "Pai Pedro",
            "Paineiras",
            "Pains",
            "Paiva",
            "Palma",
            "Palm\u00f3polis",
            "Papagaios",
            "Par\u00e1 de Minas",
            "Paracatu",
            "Paragua\u00e7u",
            "Parais\u00f3polis",
            "Paraopeba",
            "Passa Quatro",
            "Passa Tempo",
            "Passa-Vinte",
            "Passab\u00e9m",
            "Passos",
            "Patis",
            "Patos de Minas",
            "Patroc\u00ednio",
            "Patroc\u00ednio do Muria\u00e9",
            "Paula C\u00e2ndido",
            "Paulistas",
            "Pav\u00e3o",
            "Pe\u00e7anha",
            "Pedra Azul",
            "Pedra Bonita",
            "Pedra do Anta",
            "Pedra do Indai\u00e1",
            "Pedra Dourada",
            "Pedralva",
            "Pedras de Maria da Cruz",
            "Pedrin\u00f3polis",
            "Pedro Leopoldo",
            "Pedro Teixeira",
            "Pequeri",
            "Pequi",
            "Perdig\u00e3o",
            "Perdizes",
            "Perd\u00f5es",
            "Periquito",
            "Pescador",
            "Piau",
            "Piedade de Caratinga",
            "Piedade de Ponte Nova",
            "Piedade do Rio Grande",
            "Piedade dos Gerais",
            "Pimenta",
            "Pingo-d'\u00c1gua",
            "Pint\u00f3polis",
            "Piracema",
            "Pirajuba",
            "Piranga",
            "Pirangu\u00e7u",
            "Piranguinho",
            "Pirapetinga",
            "Pirapora",
            "Pira\u00faba",
            "Pitangui",
            "Piumhi",
            "Planura",
            "Po\u00e7o Fundo",
            "Po\u00e7os de Caldas",
            "Pocrane",
            "Pomp\u00e9u",
            "Ponte Nova",
            "Ponto Chique",
            "Ponto dos Volantes",
            "Porteirinha",
            "Porto Firme",
            "Pot\u00e9",
            "Pouso Alegre",
            "Pouso Alto",
            "Prados",
            "Prata",
            "Prat\u00e1polis",
            "Pratinha",
            "Presidente Bernardes",
            "Presidente Juscelino",
            "Presidente Kubitschek",
            "Presidente Oleg\u00e1rio",
            "Prudente de Morais",
            "Quartel Geral",
            "Queluzito",
            "Raposos",
            "Raul Soares",
            "Recreio",
            "Reduto",
            "Resende Costa",
            "Resplendor",
            "Ressaquinha",
            "Riachinho",
            "Riacho dos Machados",
            "Ribeir\u00e3o das Neves",
            "Ribeir\u00e3o Vermelho",
            "Rio Acima",
            "Rio Casca",
            "Rio do Prado",
            "Rio Doce",
            "Rio Espera",
            "Rio Manso",
            "Rio Novo",
            "Rio Parana\u00edba",
            "Rio Pardo de Minas",
            "Rio Piracicaba",
            "Rio Pomba",
            "Rio Preto",
            "Rio Vermelho",
            "Rit\u00e1polis",
            "Rochedo de Minas",
            "Rodeiro",
            "Romaria",
            "Ros\u00e1rio da Limeira",
            "Rubelita",
            "Rubim",
            "Sabar\u00e1",
            "Sabin\u00f3polis",
            "Sacramento",
            "Salinas",
            "Salto da Divisa",
            "Santa B\u00e1rbara",
            "Santa B\u00e1rbara do Leste",
            "Santa B\u00e1rbara do Monte Verde",
            "Santa B\u00e1rbara do Tug\u00fario",
            "Santa Cruz de Minas",
            "Santa Cruz de Salinas",
            "Santa Cruz do Escalvado",
            "Santa Efig\u00eania de Minas",
            "Santa F\u00e9 de Minas",
            "Santa Helena de Minas",
            "Santa Juliana",
            "Santa Luzia",
            "Santa Margarida",
            "Santa Maria de Itabira",
            "Santa Maria do Salto",
            "Santa Maria do Sua\u00e7u\u00ed",
            "Santa Rita de Caldas",
            "Santa Rita de Ibitipoca",
            "Santa Rita de Jacutinga",
            "Santa Rita de Minas",
            "Santa Rita do Itueto",
            "Santa Rita do Sapuca\u00ed",
            "Santa Rosa da Serra",
            "Santa Vit\u00f3ria",
            "Santana da Vargem",
            "Santana de Cataguases",
            "Santana de Pirapama",
            "Santana do Deserto",
            "Santana do Garamb\u00e9u",
            "Santana do Jacar\u00e9",
            "Santana do Manhua\u00e7u",
            "Santana do Para\u00edso",
            "Santana do Riacho",
            "Santana dos Montes",
            "Santo Ant\u00f4nio do Amparo",
            "Santo Ant\u00f4nio do Aventureiro",
            "Santo Ant\u00f4nio do Grama",
            "Santo Ant\u00f4nio do Itamb\u00e9",
            "Santo Ant\u00f4nio do Jacinto",
            "Santo Ant\u00f4nio do Monte",
            "Santo Ant\u00f4nio do Retiro",
            "Santo Ant\u00f4nio do Rio Abaixo",
            "Santo Hip\u00f3lito",
            "Santos Dumont",
            "S\u00e3o Bento Abade",
            "S\u00e3o Br\u00e1s do Sua\u00e7u\u00ed",
            "S\u00e3o Domingos das Dores",
            "S\u00e3o Domingos do Prata",
            "S\u00e3o F\u00e9lix de Minas",
            "S\u00e3o Francisco",
            "S\u00e3o Francisco de Paula",
            "S\u00e3o Francisco de Sales",
            "S\u00e3o Francisco do Gl\u00f3ria",
            "S\u00e3o Geraldo",
            "S\u00e3o Geraldo da Piedade",
            "S\u00e3o Geraldo do Baixio",
            "S\u00e3o Gon\u00e7alo do Abaet\u00e9",
            "S\u00e3o Gon\u00e7alo do Par\u00e1",
            "S\u00e3o Gon\u00e7alo do Rio Abaixo",
            "S\u00e3o Gon\u00e7alo do Rio Preto",
            "S\u00e3o Gon\u00e7alo do Sapuca\u00ed",
            "S\u00e3o Gotardo",
            "S\u00e3o Jo\u00e3o Batista do Gl\u00f3ria",
            "S\u00e3o Jo\u00e3o da Lagoa",
            "S\u00e3o Jo\u00e3o da Mata",
            "S\u00e3o Jo\u00e3o da Ponte",
            "S\u00e3o Jo\u00e3o das Miss\u00f5es",
            "S\u00e3o Jo\u00e3o del Rei",
            "S\u00e3o Jo\u00e3o do Manhua\u00e7u",
            "S\u00e3o Jo\u00e3o do Manteninha",
            "S\u00e3o Jo\u00e3o do Oriente",
            "S\u00e3o Jo\u00e3o do Pacu\u00ed",
            "S\u00e3o Jo\u00e3o do Para\u00edso",
            "S\u00e3o Jo\u00e3o Evangelista",
            "S\u00e3o Jo\u00e3o Nepomuceno",
            "S\u00e3o Joaquim de Bicas",
            "S\u00e3o Jos\u00e9 da Barra",
            "S\u00e3o Jos\u00e9 da Lapa",
            "S\u00e3o Jos\u00e9 da Safira",
            "S\u00e3o Jos\u00e9 da Varginha",
            "S\u00e3o Jos\u00e9 do Alegre",
            "S\u00e3o Jos\u00e9 do Divino",
            "S\u00e3o Jos\u00e9 do Goiabal",
            "S\u00e3o Jos\u00e9 do Jacuri",
            "S\u00e3o Jos\u00e9 do Mantimento",
            "S\u00e3o Louren\u00e7o",
            "S\u00e3o Miguel do Anta",
            "S\u00e3o Pedro da Uni\u00e3o",
            "S\u00e3o Pedro do Sua\u00e7u\u00ed",
            "S\u00e3o Pedro dos Ferros",
            "S\u00e3o Rom\u00e3o",
            "S\u00e3o Roque de Minas",
            "S\u00e3o Sebasti\u00e3o da Bela Vista",
            "S\u00e3o Sebasti\u00e3o da Vargem Alegre",
            "S\u00e3o Sebasti\u00e3o do Anta",
            "S\u00e3o Sebasti\u00e3o do Maranh\u00e3o",
            "S\u00e3o Sebasti\u00e3o do Oeste",
            "S\u00e3o Sebasti\u00e3o do Para\u00edso",
            "S\u00e3o Sebasti\u00e3o do Rio Preto",
            "S\u00e3o Sebasti\u00e3o do Rio Verde",
            "S\u00e3o Thom\u00e9 das Letras",
            "S\u00e3o Tiago",
            "S\u00e3o Tom\u00e1s de Aquino",
            "S\u00e3o Vicente de Minas",
            "Sapuca\u00ed-Mirim",
            "Sardo\u00e1",
            "Sarzedo",
            "Sem-Peixe",
            "Senador Amaral",
            "Senador Cortes",
            "Senador Firmino",
            "Senador Jos\u00e9 Bento",
            "Senador Modestino Gon\u00e7alves",
            "Senhora de Oliveira",
            "Senhora do Porto",
            "Senhora dos Rem\u00e9dios",
            "Sericita",
            "Seritinga",
            "Serra Azul de Minas",
            "Serra da Saudade",
            "Serra do Salitre",
            "Serra dos Aimor\u00e9s",
            "Serrania",
            "Serran\u00f3polis de Minas",
            "Serranos",
            "Serro",
            "Sete Lagoas",
            "Setubinha",
            "Silveir\u00e2nia",
            "Silvian\u00f3polis",
            "Sim\u00e3o Pereira",
            "Simon\u00e9sia",
            "Sobr\u00e1lia",
            "Soledade de Minas",
            "Tabuleiro",
            "Taiobeiras",
            "Taparuba",
            "Tapira",
            "Tapira\u00ed",
            "Taquara\u00e7u de Minas",
            "Tarumirim",
            "Teixeiras",
            "Te\u00f3filo Otoni",
            "Tim\u00f3teo",
            "Tiradentes",
            "Tiros",
            "Tocantins",
            "Tocos do Moji",
            "Toledo",
            "Tombos",
            "Tr\u00eas Cora\u00e7\u00f5es",
            "Tr\u00eas Marias",
            "Tr\u00eas Pontas",
            "Tumiritinga",
            "Tupaciguara",
            "Turmalina",
            "Turvol\u00e2ndia",
            "Ub\u00e1",
            "Uba\u00ed",
            "Ubaporanga",
            "Uberaba",
            "Uberl\u00e2ndia",
            "Umburatiba",
            "Una\u00ed",
            "Uni\u00e3o de Minas",
            "Uruana de Minas",
            "Uruc\u00e2nia",
            "Urucuia",
            "Vargem Alegre",
            "Vargem Bonita",
            "Vargem Grande do Rio Pardo",
            "Varginha",
            "Varj\u00e3o de Minas",
            "V\u00e1rzea da Palma",
            "Varzel\u00e2ndia",
            "Vazante",
            "Verdel\u00e2ndia",
            "Veredinha",
            "Ver\u00edssimo",
            "Vermelho Novo",
            "Vespasiano",
            "Vi\u00e7osa",
            "Vieiras",
            "Virgem da Lapa",
            "Virg\u00ednia",
            "Virgin\u00f3polis",
            "Virgol\u00e2ndia",
            "Visconde do Rio Branco",
            "Volta Grande",
            "Wenceslau Braz"
        ],
        ES: [
            "Afonso Cl\u00e1udio",
            "\u00c1gua Doce do Norte",
            "\u00c1guia Branca",
            "Alegre",
            "Alfredo Chaves",
            "Alto Rio Novo",
            "Anchieta",
            "Apiac\u00e1",
            "Aracruz",
            "Atilio Vivacqua",
            "Baixo Guandu",
            "Barra de S\u00e3o Francisco",
            "Boa Esperan\u00e7a",
            "Bom Jesus do Norte",
            "Brejetuba",
            "Cachoeiro de Itapemirim",
            "Cariacica",
            "Castelo",
            "Colatina",
            "Concei\u00e7\u00e3o da Barra",
            "Concei\u00e7\u00e3o do Castelo",
            "Divino de S\u00e3o Louren\u00e7o",
            "Domingos Martins",
            "Dores do Rio Preto",
            "Ecoporanga",
            "Fund\u00e3o",
            "Governador Lindenberg",
            "Gua\u00e7u\u00ed",
            "Guarapari",
            "Ibatiba",
            "Ibira\u00e7u",
            "Ibitirama",
            "Iconha",
            "Irupi",
            "Itagua\u00e7u",
            "Itapemirim",
            "Itarana",
            "I\u00fana",
            "Jaguar\u00e9",
            "Jer\u00f4nimo Monteiro",
            "Jo\u00e3o Neiva",
            "Laranja da Terra",
            "Linhares",
            "Manten\u00f3polis",
            "Marata\u00edzes",
            "Marechal Floriano",
            "Maril\u00e2ndia",
            "Mimoso do Sul",
            "Montanha",
            "Mucurici",
            "Muniz Freire",
            "Muqui",
            "Nova Ven\u00e9cia",
            "Pancas",
            "Pedro Can\u00e1rio",
            "Pinheiros",
            "Pi\u00fama",
            "Ponto Belo",
            "Presidente Kennedy",
            "Rio Bananal",
            "Rio Novo do Sul",
            "Santa Leopoldina",
            "Santa Maria de Jetib\u00e1",
            "Santa Teresa",
            "S\u00e3o Domingos do Norte",
            "S\u00e3o Gabriel da Palha",
            "S\u00e3o Jos\u00e9 do Cal\u00e7ado",
            "S\u00e3o Mateus",
            "S\u00e3o Roque do Cana\u00e3",
            "Serra",
            "Sooretama",
            "Vargem Alta",
            "Venda Nova do Imigrante",
            "Viana",
            "Vila Pav\u00e3o",
            "Vila Val\u00e9rio",
            "Vila Velha",
            "Vit\u00f3ria"
        ],
        RJ: [
            "Angra dos Reis",
            "Aperib\u00e9",
            "Araruama",
            "Areal",
            "Arma\u00e7\u00e3o dos B\u00fazios",
            "Arraial do Cabo",
            "Barra do Pira\u00ed",
            "Barra Mansa",
            "Belford Roxo",
            "Bom Jardim",
            "Bom Jesus do Itabapoana",
            "Cabo Frio",
            "Cachoeiras de Macacu",
            "Cambuci",
            "Campos dos Goytacazes",
            "Cantagalo",
            "Carapebus",
            "Cardoso Moreira",
            "Carmo",
            "Casimiro de Abreu",
            "Comendador Levy Gasparian",
            "Concei\u00e7\u00e3o de Macabu",
            "Cordeiro",
            "Duas Barras",
            "Duque de Caxias",
            "Engenheiro Paulo de Frontin",
            "Guapimirim",
            "Iguaba Grande",
            "Itabora\u00ed",
            "Itagua\u00ed",
            "Italva",
            "Itaocara",
            "Itaperuna",
            "Itatiaia",
            "Japeri",
            "Laje do Muria\u00e9",
            "Maca\u00e9",
            "Macuco",
            "Mag\u00e9",
            "Mangaratiba",
            "Maric\u00e1",
            "Mendes",
            "Mesquita",
            "Miguel Pereira",
            "Miracema",
            "Natividade",
            "Nil\u00f3polis",
            "Niter\u00f3i",
            "Nova Friburgo",
            "Nova Igua\u00e7u",
            "Paracambi",
            "Para\u00edba do Sul",
            "Paraty",
            "Paty do Alferes",
            "Petr\u00f3polis",
            "Pinheiral",
            "Pira\u00ed",
            "Porci\u00fancula",
            "Porto Real",
            "Quatis",
            "Queimados",
            "Quissam\u00e3",
            "Resende",
            "Rio Bonito",
            "Rio Claro",
            "Rio das Flores",
            "Rio das Ostras",
            "Rio de Janeiro",
            "Santa Maria Madalena",
            "Santo Ant\u00f4nio de P\u00e1dua",
            "S\u00e3o Fid\u00e9lis",
            "S\u00e3o Francisco de Itabapoana",
            "S\u00e3o Gon\u00e7alo",
            "S\u00e3o Jo\u00e3o da Barra",
            "S\u00e3o Jo\u00e3o de Meriti",
            "S\u00e3o Jos\u00e9 de Ub\u00e1",
            "S\u00e3o Jos\u00e9 do Vale do Rio Preto",
            "S\u00e3o Pedro da Aldeia",
            "S\u00e3o Sebasti\u00e3o do Alto",
            "Sapucaia",
            "Saquarema",
            "Serop\u00e9dica",
            "Silva Jardim",
            "Sumidouro",
            "Tangu\u00e1",
            "Teres\u00f3polis",
            "Trajano de Moraes",
            "Tr\u00eas Rios",
            "Valen\u00e7a",
            "Varre-Sai",
            "Vassouras",
            "Volta Redonda"
        ],
        SP: [
            "Adamantina",
            "Adolfo",
            "Agua\u00ed",
            "\u00c1guas da Prata",
            "\u00c1guas de Lind\u00f3ia",
            "\u00c1guas de Santa B\u00e1rbara",
            "\u00c1guas de S\u00e3o Pedro",
            "Agudos",
            "Alambari",
            "Alfredo Marcondes",
            "Altair",
            "Altin\u00f3polis",
            "Alto Alegre",
            "Alum\u00ednio",
            "\u00c1lvares Florence",
            "\u00c1lvares Machado",
            "\u00c1lvaro de Carvalho",
            "Alvinl\u00e2ndia",
            "Americana",
            "Am\u00e9rico Brasiliense",
            "Am\u00e9rico de Campos",
            "Amparo",
            "Anal\u00e2ndia",
            "Andradina",
            "Angatuba",
            "Anhembi",
            "Anhumas",
            "Aparecida",
            "Aparecida d'Oeste",
            "Apia\u00ed",
            "Ara\u00e7ariguama",
            "Ara\u00e7atuba",
            "Ara\u00e7oiaba da Serra",
            "Aramina",
            "Arandu",
            "Arape\u00ed",
            "Araraquara",
            "Araras",
            "Arco-\u00cdris",
            "Arealva",
            "Areias",
            "Arei\u00f3polis",
            "Ariranha",
            "Artur Nogueira",
            "Aruj\u00e1",
            "Asp\u00e1sia",
            "Assis",
            "Atibaia",
            "Auriflama",
            "Ava\u00ed",
            "Avanhandava",
            "Avar\u00e9",
            "Bady Bassitt",
            "Balbinos",
            "B\u00e1lsamo",
            "Bananal",
            "Bar\u00e3o de Antonina",
            "Barbosa",
            "Bariri",
            "Barra Bonita",
            "Barra do Chap\u00e9u",
            "Barra do Turvo",
            "Barretos",
            "Barrinha",
            "Barueri",
            "Bastos",
            "Batatais",
            "Bauru",
            "Bebedouro",
            "Bento de Abreu",
            "Bernardino de Campos",
            "Bertioga",
            "Bilac",
            "Birigui",
            "Biritiba-Mirim",
            "Boa Esperan\u00e7a do Sul",
            "Bocaina",
            "Bofete",
            "Boituva",
            "Bom Jesus dos Perd\u00f5es",
            "Bom Sucesso de Itarar\u00e9",
            "Bor\u00e1",
            "Borac\u00e9ia",
            "Borborema",
            "Borebi",
            "Botucatu",
            "Bragan\u00e7a Paulista",
            "Bra\u00fana",
            "Brejo Alegre",
            "Brodowski",
            "Brotas",
            "Buri",
            "Buritama",
            "Buritizal",
            "Cabr\u00e1lia Paulista",
            "Cabre\u00fava",
            "Ca\u00e7apava",
            "Cachoeira Paulista",
            "Caconde",
            "Cafel\u00e2ndia",
            "Caiabu",
            "Caieiras",
            "Caiu\u00e1",
            "Cajamar",
            "Cajati",
            "Cajobi",
            "Cajuru",
            "Campina do Monte Alegre",
            "Campinas",
            "Campo Limpo Paulista",
            "Campos do Jord\u00e3o",
            "Campos Novos Paulista",
            "Canan\u00e9ia",
            "Canas",
            "C\u00e2ndido Mota",
            "C\u00e2ndido Rodrigues",
            "Canitar",
            "Cap\u00e3o Bonito",
            "Capela do Alto",
            "Capivari",
            "Caraguatatuba",
            "Carapicu\u00edba",
            "Cardoso",
            "Casa Branca",
            "C\u00e1ssia dos Coqueiros",
            "Castilho",
            "Catanduva",
            "Catigu\u00e1",
            "Cedral",
            "Cerqueira C\u00e9sar",
            "Cerquilho",
            "Ces\u00e1rio Lange",
            "Charqueada",
            "Chavantes",
            "Clementina",
            "Colina",
            "Col\u00f4mbia",
            "Conchal",
            "Conchas",
            "Cordeir\u00f3polis",
            "Coroados",
            "Coronel Macedo",
            "Corumbata\u00ed",
            "Cosm\u00f3polis",
            "Cosmorama",
            "Cotia",
            "Cravinhos",
            "Cristais Paulista",
            "Cruz\u00e1lia",
            "Cruzeiro",
            "Cubat\u00e3o",
            "Cunha",
            "Descalvado",
            "Diadema",
            "Dirce Reis",
            "Divinol\u00e2ndia",
            "Dobrada",
            "Dois C\u00f3rregos",
            "Dolcin\u00f3polis",
            "Dourado",
            "Dracena",
            "Duartina",
            "Dumont",
            "Echapor\u00e3",
            "Eldorado",
            "Elias Fausto",
            "Elisi\u00e1rio",
            "Emba\u00faba",
            "Embu das Artes",
            "Embu-Gua\u00e7u",
            "Emilian\u00f3polis",
            "Engenheiro Coelho",
            "Esp\u00edrito Santo do Pinhal",
            "Esp\u00edrito Santo do Turvo",
            "Estiva Gerbi",
            "Estrela d'Oeste",
            "Estrela do Norte",
            "Euclides da Cunha Paulista",
            "Fartura",
            "Fernando Prestes",
            "Fernand\u00f3polis",
            "Fern\u00e3o",
            "Ferraz de Vasconcelos",
            "Flora Rica",
            "Floreal",
            "Fl\u00f3rida Paulista",
            "Flor\u00ednia",
            "Franca",
            "Francisco Morato",
            "Franco da Rocha",
            "Gabriel Monteiro",
            "G\u00e1lia",
            "Gar\u00e7a",
            "Gast\u00e3o Vidigal",
            "Gavi\u00e3o Peixoto",
            "General Salgado",
            "Getulina",
            "Glic\u00e9rio",
            "Guai\u00e7ara",
            "Guaimb\u00ea",
            "Gua\u00edra",
            "Guapia\u00e7u",
            "Guapiara",
            "Guar\u00e1",
            "Guara\u00e7a\u00ed",
            "Guaraci",
            "Guarani d'Oeste",
            "Guarant\u00e3",
            "Guararapes",
            "Guararema",
            "Guaratinguet\u00e1",
            "Guare\u00ed",
            "Guariba",
            "Guaruj\u00e1",
            "Guarulhos",
            "Guatapar\u00e1",
            "Guzol\u00e2ndia",
            "Hercul\u00e2ndia",
            "Holambra",
            "Hortol\u00e2ndia",
            "Iacanga",
            "Iacri",
            "Iaras",
            "Ibat\u00e9",
            "Ibir\u00e1",
            "Ibirarema",
            "Ibitinga",
            "Ibi\u00fana",
            "Ic\u00e9m",
            "Iep\u00ea",
            "Igara\u00e7u do Tiet\u00ea",
            "Igarapava",
            "Igarat\u00e1",
            "Iguape",
            "Ilha Comprida",
            "Ilha Solteira",
            "Ilhabela",
            "Indaiatuba",
            "Indiana",
            "Indiapor\u00e3",
            "In\u00fabia Paulista",
            "Ipaussu",
            "Iper\u00f3",
            "Ipe\u00fana",
            "Ipigu\u00e1",
            "Iporanga",
            "Ipu\u00e3",
            "Iracem\u00e1polis",
            "Irapu\u00e3",
            "Irapuru",
            "Itaber\u00e1",
            "Ita\u00ed",
            "Itajobi",
            "Itaju",
            "Itanha\u00e9m",
            "Ita\u00f3ca",
            "Itapecerica da Serra",
            "Itapetininga",
            "Itapeva",
            "Itapevi",
            "Itapira",
            "Itapirapu\u00e3 Paulista",
            "It\u00e1polis",
            "Itaporanga",
            "Itapu\u00ed",
            "Itapura",
            "Itaquaquecetuba",
            "Itarar\u00e9",
            "Itariri",
            "Itatiba",
            "Itatinga",
            "Itirapina",
            "Itirapu\u00e3",
            "Itobi",
            "Itu",
            "Itupeva",
            "Ituverava",
            "Jaborandi",
            "Jaboticabal",
            "Jacare\u00ed",
            "Jaci",
            "Jacupiranga",
            "Jaguari\u00fana",
            "Jales",
            "Jambeiro",
            "Jandira",
            "Jardin\u00f3polis",
            "Jarinu",
            "Ja\u00fa",
            "Jeriquara",
            "Joan\u00f3polis",
            "Jo\u00e3o Ramalho",
            "Jos\u00e9 Bonif\u00e1cio",
            "J\u00falio Mesquita",
            "Jumirim",
            "Jundia\u00ed",
            "Junqueir\u00f3polis",
            "Juqui\u00e1",
            "Juquitiba",
            "Lagoinha",
            "Laranjal Paulista",
            "Lav\u00ednia",
            "Lavrinhas",
            "Leme",
            "Len\u00e7\u00f3is Paulista",
            "Limeira",
            "Lind\u00f3ia",
            "Lins",
            "Lorena",
            "Lourdes",
            "Louveira",
            "Luc\u00e9lia",
            "Lucian\u00f3polis",
            "Lu\u00eds Ant\u00f4nio",
            "Luizi\u00e2nia",
            "Lup\u00e9rcio",
            "Lut\u00e9cia",
            "Macatuba",
            "Macaubal",
            "Maced\u00f4nia",
            "Magda",
            "Mairinque",
            "Mairipor\u00e3",
            "Manduri",
            "Marab\u00e1 Paulista",
            "Maraca\u00ed",
            "Marapoama",
            "Mari\u00e1polis",
            "Mar\u00edlia",
            "Marin\u00f3polis",
            "Martin\u00f3polis",
            "Mat\u00e3o",
            "Mau\u00e1",
            "Mendon\u00e7a",
            "Meridiano",
            "Mes\u00f3polis",
            "Miguel\u00f3polis",
            "Mineiros do Tiet\u00ea",
            "Mira Estrela",
            "Miracatu",
            "Mirand\u00f3polis",
            "Mirante do Paranapanema",
            "Mirassol",
            "Mirassol\u00e2ndia",
            "Mococa",
            "Mogi das Cruzes",
            "Mogi Gua\u00e7u",
            "Mogi Mirim",
            "Mombuca",
            "Mon\u00e7\u00f5es",
            "Mongagu\u00e1",
            "Monte Alegre do Sul",
            "Monte Alto",
            "Monte Apraz\u00edvel",
            "Monte Azul Paulista",
            "Monte Castelo",
            "Monte Mor",
            "Monteiro Lobato",
            "Morro Agudo",
            "Morungaba",
            "Motuca",
            "Murutinga do Sul",
            "Nantes",
            "Narandiba",
            "Natividade da Serra",
            "Nazar\u00e9 Paulista",
            "Neves Paulista",
            "Nhandeara",
            "Nipo\u00e3",
            "Nova Alian\u00e7a",
            "Nova Campina",
            "Nova Cana\u00e3 Paulista",
            "Nova Castilho",
            "Nova Europa",
            "Nova Granada",
            "Nova Guataporanga",
            "Nova Independ\u00eancia",
            "Nova Luzit\u00e2nia",
            "Nova Odessa",
            "Novais",
            "Novo Horizonte",
            "Nuporanga",
            "Ocau\u00e7u",
            "\u00d3leo",
            "Ol\u00edmpia",
            "Onda Verde",
            "Oriente",
            "Orindi\u00fava",
            "Orl\u00e2ndia",
            "Osasco",
            "Oscar Bressane",
            "Osvaldo Cruz",
            "Ourinhos",
            "Ouro Verde",
            "Ouroeste",
            "Pacaembu",
            "Palestina",
            "Palmares Paulista",
            "Palmeira d'Oeste",
            "Palmital",
            "Panorama",
            "Paragua\u00e7u Paulista",
            "Paraibuna",
            "Para\u00edso",
            "Paranapanema",
            "Paranapu\u00e3",
            "Parapu\u00e3",
            "Pardinho",
            "Pariquera-A\u00e7u",
            "Parisi",
            "Patroc\u00ednio Paulista",
            "Paulic\u00e9ia",
            "Paul\u00ednia",
            "Paulist\u00e2nia",
            "Paulo de Faria",
            "Pederneiras",
            "Pedra Bela",
            "Pedran\u00f3polis",
            "Pedregulho",
            "Pedreira",
            "Pedrinhas Paulista",
            "Pedro de Toledo",
            "Pen\u00e1polis",
            "Pereira Barreto",
            "Pereiras",
            "Peru\u00edbe",
            "Piacatu",
            "Piedade",
            "Pilar do Sul",
            "Pindamonhangaba",
            "Pindorama",
            "Pinhalzinho",
            "Piquerobi",
            "Piquete",
            "Piracaia",
            "Piracicaba",
            "Piraju",
            "Piraju\u00ed",
            "Pirangi",
            "Pirapora do Bom Jesus",
            "Pirapozinho",
            "Pirassununga",
            "Piratininga",
            "Pitangueiras",
            "Planalto",
            "Platina",
            "Po\u00e1",
            "Poloni",
            "Pomp\u00e9ia",
            "Ponga\u00ed",
            "Pontal",
            "Pontalinda",
            "Pontes Gestal",
            "Populina",
            "Porangaba",
            "Porto Feliz",
            "Porto Ferreira",
            "Potim",
            "Potirendaba",
            "Pracinha",
            "Prad\u00f3polis",
            "Praia Grande",
            "Prat\u00e2nia",
            "Presidente Alves",
            "Presidente Bernardes",
            "Presidente Epit\u00e1cio",
            "Presidente Prudente",
            "Presidente Venceslau",
            "Promiss\u00e3o",
            "Quadra",
            "Quat\u00e1",
            "Queiroz",
            "Queluz",
            "Quintana",
            "Rafard",
            "Rancharia",
            "Reden\u00e7\u00e3o da Serra",
            "Regente Feij\u00f3",
            "Regin\u00f3polis",
            "Registro",
            "Restinga",
            "Ribeira",
            "Ribeir\u00e3o Bonito",
            "Ribeir\u00e3o Branco",
            "Ribeir\u00e3o Corrente",
            "Ribeir\u00e3o do Sul",
            "Ribeir\u00e3o dos \u00cdndios",
            "Ribeir\u00e3o Grande",
            "Ribeir\u00e3o Pires",
            "Ribeir\u00e3o Preto",
            "Rifaina",
            "Rinc\u00e3o",
            "Rin\u00f3polis",
            "Rio Claro",
            "Rio das Pedras",
            "Rio Grande da Serra",
            "Riol\u00e2ndia",
            "Riversul",
            "Rosana",
            "Roseira",
            "Rubi\u00e1cea",
            "Rubin\u00e9ia",
            "Sabino",
            "Sagres",
            "Sales",
            "Sales Oliveira",
            "Sales\u00f3polis",
            "Salmour\u00e3o",
            "Saltinho",
            "Salto",
            "Salto de Pirapora",
            "Salto Grande",
            "Sandovalina",
            "Santa Ad\u00e9lia",
            "Santa Albertina",
            "Santa B\u00e1rbara d'Oeste",
            "Santa Branca",
            "Santa Clara d'Oeste",
            "Santa Cruz da Concei\u00e7\u00e3o",
            "Santa Cruz da Esperan\u00e7a",
            "Santa Cruz das Palmeiras",
            "Santa Cruz do Rio Pardo",
            "Santa Ernestina",
            "Santa F\u00e9 do Sul",
            "Santa Gertrudes",
            "Santa Isabel",
            "Santa L\u00facia",
            "Santa Maria da Serra",
            "Santa Mercedes",
            "Santa Rita d'Oeste",
            "Santa Rita do Passa Quatro",
            "Santa Rosa de Viterbo",
            "Santa Salete",
            "Santana da Ponte Pensa",
            "Santana de Parna\u00edba",
            "Santo Anast\u00e1cio",
            "Santo Andr\u00e9",
            "Santo Ant\u00f4nio da Alegria",
            "Santo Ant\u00f4nio de Posse",
            "Santo Ant\u00f4nio do Aracangu\u00e1",
            "Santo Ant\u00f4nio do Jardim",
            "Santo Ant\u00f4nio do Pinhal",
            "Santo Expedito",
            "Sant\u00f3polis do Aguape\u00ed",
            "Santos",
            "S\u00e3o Bento do Sapuca\u00ed",
            "S\u00e3o Bernardo do Campo",
            "S\u00e3o Caetano do Sul",
            "S\u00e3o Carlos",
            "S\u00e3o Francisco",
            "S\u00e3o Jo\u00e3o da Boa Vista",
            "S\u00e3o Jo\u00e3o das Duas Pontes",
            "S\u00e3o Jo\u00e3o de Iracema",
            "S\u00e3o Jo\u00e3o do Pau d'Alho",
            "S\u00e3o Joaquim da Barra",
            "S\u00e3o Jos\u00e9 da Bela Vista",
            "S\u00e3o Jos\u00e9 do Barreiro",
            "S\u00e3o Jos\u00e9 do Rio Pardo",
            "S\u00e3o Jos\u00e9 do Rio Preto",
            "S\u00e3o Jos\u00e9 dos Campos",
            "S\u00e3o Louren\u00e7o da Serra",
            "S\u00e3o Luiz do Paraitinga",
            "S\u00e3o Manuel",
            "S\u00e3o Miguel Arcanjo",
            "S\u00e3o Paulo",
            "S\u00e3o Pedro",
            "S\u00e3o Pedro do Turvo",
            "S\u00e3o Roque",
            "S\u00e3o Sebasti\u00e3o",
            "S\u00e3o Sebasti\u00e3o da Grama",
            "S\u00e3o Sim\u00e3o",
            "S\u00e3o Vicente",
            "Sarapu\u00ed",
            "Sarutai\u00e1",
            "Sebastian\u00f3polis do Sul",
            "Serra Azul",
            "Serra Negra",
            "Serrana",
            "Sert\u00e3ozinho",
            "Sete Barras",
            "Sever\u00ednia",
            "Silveiras",
            "Socorro",
            "Sorocaba",
            "Sud Mennucci",
            "Sumar\u00e9",
            "Suzan\u00e1polis",
            "Suzano",
            "Tabapu\u00e3",
            "Tabatinga",
            "Tabo\u00e3o da Serra",
            "Taciba",
            "Tagua\u00ed",
            "Taia\u00e7u",
            "Tai\u00fava",
            "Tamba\u00fa",
            "Tanabi",
            "Tapira\u00ed",
            "Tapiratiba",
            "Taquaral",
            "Taquaritinga",
            "Taquarituba",
            "Taquariva\u00ed",
            "Tarabai",
            "Tarum\u00e3",
            "Tatu\u00ed",
            "Taubat\u00e9",
            "Tejup\u00e1",
            "Teodoro Sampaio",
            "Terra Roxa",
            "Tiet\u00ea",
            "Timburi",
            "Torre de Pedra",
            "Torrinha",
            "Trabiju",
            "Trememb\u00e9",
            "Tr\u00eas Fronteiras",
            "Tuiuti",
            "Tup\u00e3",
            "Tupi Paulista",
            "Turi\u00faba",
            "Turmalina",
            "Ubarana",
            "Ubatuba",
            "Ubirajara",
            "Uchoa",
            "Uni\u00e3o Paulista",
            "Ur\u00e2nia",
            "Uru",
            "Urup\u00eas",
            "Valentim Gentil",
            "Valinhos",
            "Valpara\u00edso",
            "Vargem",
            "Vargem Grande do Sul",
            "Vargem Grande Paulista",
            "V\u00e1rzea Paulista",
            "Vera Cruz",
            "Vinhedo",
            "Viradouro",
            "Vista Alegre do Alto",
            "Vit\u00f3ria Brasil",
            "Votorantim",
            "Votuporanga",
            "Zacarias"
        ],
        PR: [
            "Abati\u00e1",
            "Adrian\u00f3polis",
            "Agudos do Sul",
            "Almirante Tamandar\u00e9",
            "Altamira do Paran\u00e1",
            "Alto Para\u00edso",
            "Alto Paran\u00e1",
            "Alto Piquiri",
            "Alt\u00f4nia",
            "Alvorada do Sul",
            "Amapor\u00e3",
            "Amp\u00e9re",
            "Anahy",
            "Andir\u00e1",
            "\u00c2ngulo",
            "Antonina",
            "Ant\u00f4nio Olinto",
            "Apucarana",
            "Arapongas",
            "Arapoti",
            "Arapu\u00e3",
            "Araruna",
            "Arauc\u00e1ria",
            "Ariranha do Iva\u00ed",
            "Assa\u00ed",
            "Assis Chateaubriand",
            "Astorga",
            "Atalaia",
            "Balsa Nova",
            "Bandeirantes",
            "Barbosa Ferraz",
            "Barra do Jacar\u00e9",
            "Barrac\u00e3o",
            "Bela Vista da Caroba",
            "Bela Vista do Para\u00edso",
            "Bituruna",
            "Boa Esperan\u00e7a",
            "Boa Esperan\u00e7a do Igua\u00e7u",
            "Boa Ventura de S\u00e3o Roque",
            "Boa Vista da Aparecida",
            "Bocai\u00fava do Sul",
            "Bom Jesus do Sul",
            "Bom Sucesso",
            "Bom Sucesso do Sul",
            "Borraz\u00f3polis",
            "Braganey",
            "Brasil\u00e2ndia do Sul",
            "Cafeara",
            "Cafel\u00e2ndia",
            "Cafezal do Sul",
            "Calif\u00f3rnia",
            "Cambar\u00e1",
            "Camb\u00e9",
            "Cambira",
            "Campina da Lagoa",
            "Campina do Sim\u00e3o",
            "Campina Grande do Sul",
            "Campo Bonito",
            "Campo do Tenente",
            "Campo Largo",
            "Campo Magro",
            "Campo Mour\u00e3o",
            "C\u00e2ndido de Abreu",
            "Cand\u00f3i",
            "Cantagalo",
            "Capanema",
            "Capit\u00e3o Le\u00f4nidas Marques",
            "Carambe\u00ed",
            "Carl\u00f3polis",
            "Cascavel",
            "Castro",
            "Catanduvas",
            "Centen\u00e1rio do Sul",
            "Cerro Azul",
            "C\u00e9u Azul",
            "Chopinzinho",
            "Cianorte",
            "Cidade Ga\u00facha",
            "Clevel\u00e2ndia",
            "Colombo",
            "Colorado",
            "Congonhinhas",
            "Conselheiro Mairinck",
            "Contenda",
            "Corb\u00e9lia",
            "Corn\u00e9lio Proc\u00f3pio",
            "Coronel Domingos Soares",
            "Coronel Vivida",
            "Corumbata\u00ed do Sul",
            "Cruz Machado",
            "Cruzeiro do Igua\u00e7u",
            "Cruzeiro do Oeste",
            "Cruzeiro do Sul",
            "Cruzmaltina",
            "Curitiba",
            "Curi\u00fava",
            "Diamante D'Oeste",
            "Diamante do Norte",
            "Diamante do Sul",
            "Dois Vizinhos",
            "Douradina",
            "Doutor Camargo",
            "Doutor Ulysses",
            "En\u00e9as Marques",
            "Engenheiro Beltr\u00e3o",
            "Entre Rios do Oeste",
            "Esperan\u00e7a Nova",
            "Espig\u00e3o Alto do Igua\u00e7u",
            "Farol",
            "Faxinal",
            "Fazenda Rio Grande",
            "F\u00eanix",
            "Fernandes Pinheiro",
            "Figueira",
            "Flor da Serra do Sul",
            "Flora\u00ed",
            "Floresta",
            "Florest\u00f3polis",
            "Fl\u00f3rida",
            "Formosa do Oeste",
            "Foz do Igua\u00e7u",
            "Foz do Jord\u00e3o",
            "Francisco Alves",
            "Francisco Beltr\u00e3o",
            "General Carneiro",
            "Godoy Moreira",
            "Goioer\u00ea",
            "Goioxim",
            "Grandes Rios",
            "Gua\u00edra",
            "Guaira\u00e7\u00e1",
            "Guamiranga",
            "Guapirama",
            "Guaporema",
            "Guaraci",
            "Guarania\u00e7u",
            "Guarapuava",
            "Guaraque\u00e7aba",
            "Guaratuba",
            "Hon\u00f3rio Serpa",
            "Ibaiti",
            "Ibema",
            "Ibipor\u00e3",
            "Icara\u00edma",
            "Iguara\u00e7u",
            "Iguatu",
            "Imba\u00fa",
            "Imbituva",
            "In\u00e1cio Martins",
            "Inaj\u00e1",
            "Indian\u00f3polis",
            "Ipiranga",
            "Ipor\u00e3",
            "Iracema do Oeste",
            "Irati",
            "Iretama",
            "Itaguaj\u00e9",
            "Itaipul\u00e2ndia",
            "Itambarac\u00e1",
            "Itamb\u00e9",
            "Itapejara d'Oeste",
            "Itaperu\u00e7u",
            "Ita\u00fana do Sul",
            "Iva\u00ed",
            "Ivaipor\u00e3",
            "Ivat\u00e9",
            "Ivatuba",
            "Jaboti",
            "Jacarezinho",
            "Jaguapit\u00e3",
            "Jaguaria\u00edva",
            "Jandaia do Sul",
            "Jani\u00f3polis",
            "Japira",
            "Japur\u00e1",
            "Jardim Alegre",
            "Jardim Olinda",
            "Jataizinho",
            "Jesu\u00edtas",
            "Joaquim T\u00e1vora",
            "Jundia\u00ed do Sul",
            "Juranda",
            "Jussara",
            "Kalor\u00e9",
            "Lapa",
            "Laranjal",
            "Laranjeiras do Sul",
            "Le\u00f3polis",
            "Lidian\u00f3polis",
            "Lindoeste",
            "Loanda",
            "Lobato",
            "Londrina",
            "Luiziana",
            "Lunardelli",
            "Lupion\u00f3polis",
            "Mallet",
            "Mambor\u00ea",
            "Mandagua\u00e7u",
            "Mandaguari",
            "Mandirituba",
            "Manfrin\u00f3polis",
            "Mangueirinha",
            "Manoel Ribas",
            "Marechal C\u00e2ndido Rondon",
            "Maria Helena",
            "Marialva",
            "Maril\u00e2ndia do Sul",
            "Marilena",
            "Mariluz",
            "Maring\u00e1",
            "Mari\u00f3polis",
            "Marip\u00e1",
            "Marmeleiro",
            "Marquinho",
            "Marumbi",
            "Matel\u00e2ndia",
            "Matinhos",
            "Mato Rico",
            "Mau\u00e1 da Serra",
            "Medianeira",
            "Mercedes",
            "Mirador",
            "Miraselva",
            "Missal",
            "Moreira Sales",
            "Morretes",
            "Munhoz de Melo",
            "Nossa Senhora das Gra\u00e7as",
            "Nova Alian\u00e7a do Iva\u00ed",
            "Nova Am\u00e9rica da Colina",
            "Nova Aurora",
            "Nova Cantu",
            "Nova Esperan\u00e7a",
            "Nova Esperan\u00e7a do Sudoeste",
            "Nova F\u00e1tima",
            "Nova Laranjeiras",
            "Nova Londrina",
            "Nova Ol\u00edmpia",
            "Nova Prata do Igua\u00e7u",
            "Nova Santa B\u00e1rbara",
            "Nova Santa Rosa",
            "Nova Tebas",
            "Novo Itacolomi",
            "Ortigueira",
            "Ourizona",
            "Ouro Verde do Oeste",
            "Pai\u00e7andu",
            "Palmas",
            "Palmeira",
            "Palmital",
            "Palotina",
            "Para\u00edso do Norte",
            "Paranacity",
            "Paranagu\u00e1",
            "Paranapoema",
            "Paranava\u00ed",
            "Pato Bragado",
            "Pato Branco",
            "Paula Freitas",
            "Paulo Frontin",
            "Peabiru",
            "Perobal",
            "P\u00e9rola",
            "P\u00e9rola d'Oeste",
            "Pi\u00ean",
            "Pinhais",
            "Pinhal de S\u00e3o Bento",
            "Pinhal\u00e3o",
            "Pinh\u00e3o",
            "Pira\u00ed do Sul",
            "Piraquara",
            "Pitanga",
            "Pitangueiras",
            "Planaltina do Paran\u00e1",
            "Planalto",
            "Ponta Grossa",
            "Pontal do Paran\u00e1",
            "Porecatu",
            "Porto Amazonas",
            "Porto Barreiro",
            "Porto Rico",
            "Porto Vit\u00f3ria",
            "Prado Ferreira",
            "Pranchita",
            "Presidente Castelo Branco",
            "Primeiro de Maio",
            "Prudent\u00f3polis",
            "Quarto Centen\u00e1rio",
            "Quatigu\u00e1",
            "Quatro Barras",
            "Quatro Pontes",
            "Quedas do Igua\u00e7u",
            "Quer\u00eancia do Norte",
            "Quinta do Sol",
            "Quitandinha",
            "Ramil\u00e2ndia",
            "Rancho Alegre",
            "Rancho Alegre D'Oeste",
            "Realeza",
            "Rebou\u00e7as",
            "Renascen\u00e7a",
            "Reserva",
            "Reserva do Igua\u00e7u",
            "Ribeir\u00e3o Claro",
            "Ribeir\u00e3o do Pinhal",
            "Rio Azul",
            "Rio Bom",
            "Rio Bonito do Igua\u00e7u",
            "Rio Branco do Iva\u00ed",
            "Rio Branco do Sul",
            "Rio Negro",
            "Rol\u00e2ndia",
            "Roncador",
            "Rondon",
            "Ros\u00e1rio do Iva\u00ed",
            "Sab\u00e1udia",
            "Salgado Filho",
            "Salto do Itarar\u00e9",
            "Salto do Lontra",
            "Santa Am\u00e9lia",
            "Santa Cec\u00edlia do Pav\u00e3o",
            "Santa Cruz de Monte Castelo",
            "Santa F\u00e9",
            "Santa Helena",
            "Santa In\u00eas",
            "Santa Isabel do Iva\u00ed",
            "Santa Izabel do Oeste",
            "Santa L\u00facia",
            "Santa Maria do Oeste",
            "Santa Mariana",
            "Santa M\u00f4nica",
            "Santa Tereza do Oeste",
            "Santa Terezinha de Itaipu",
            "Santana do Itarar\u00e9",
            "Santo Ant\u00f4nio da Platina",
            "Santo Ant\u00f4nio do Caiu\u00e1",
            "Santo Ant\u00f4nio do Para\u00edso",
            "Santo Ant\u00f4nio do Sudoeste",
            "Santo In\u00e1cio",
            "S\u00e3o Carlos do Iva\u00ed",
            "S\u00e3o Jer\u00f4nimo da Serra",
            "S\u00e3o Jo\u00e3o",
            "S\u00e3o Jo\u00e3o do Caiu\u00e1",
            "S\u00e3o Jo\u00e3o do Iva\u00ed",
            "S\u00e3o Jo\u00e3o do Triunfo",
            "S\u00e3o Jorge d'Oeste",
            "S\u00e3o Jorge do Iva\u00ed",
            "S\u00e3o Jorge do Patroc\u00ednio",
            "S\u00e3o Jos\u00e9 da Boa Vista",
            "S\u00e3o Jos\u00e9 das Palmeiras",
            "S\u00e3o Jos\u00e9 dos Pinhais",
            "S\u00e3o Manoel do Paran\u00e1",
            "S\u00e3o Mateus do Sul",
            "S\u00e3o Miguel do Igua\u00e7u",
            "S\u00e3o Pedro do Igua\u00e7u",
            "S\u00e3o Pedro do Iva\u00ed",
            "S\u00e3o Pedro do Paran\u00e1",
            "S\u00e3o Sebasti\u00e3o da Amoreira",
            "S\u00e3o Tom\u00e9",
            "Sapopema",
            "Sarandi",
            "Saudade do Igua\u00e7u",
            "Seng\u00e9s",
            "Serran\u00f3polis do Igua\u00e7u",
            "Sertaneja",
            "Sertan\u00f3polis",
            "Siqueira Campos",
            "Sulina",
            "Tamarana",
            "Tamboara",
            "Tapejara",
            "Tapira",
            "Teixeira Soares",
            "Tel\u00eamaco Borba",
            "Terra Boa",
            "Terra Rica",
            "Terra Roxa",
            "Tibagi",
            "Tijucas do Sul",
            "Toledo",
            "Tomazina",
            "Tr\u00eas Barras do Paran\u00e1",
            "Tunas do Paran\u00e1",
            "Tuneiras do Oeste",
            "Tup\u00e3ssi",
            "Turvo",
            "Ubirat\u00e3",
            "Umuarama",
            "Uni\u00e3o da Vit\u00f3ria",
            "Uniflor",
            "Ura\u00ed",
            "Ventania",
            "Vera Cruz do Oeste",
            "Ver\u00ea",
            "Virmond",
            "Vitorino",
            "Wenceslau Braz",
            "Xambr\u00ea"
        ],
        SC: [
            "Abdon Batista",
            "Abelardo Luz",
            "Agrol\u00e2ndia",
            "Agron\u00f4mica",
            "\u00c1gua Doce",
            "\u00c1guas de Chapec\u00f3",
            "\u00c1guas Frias",
            "\u00c1guas Mornas",
            "Alfredo Wagner",
            "Alto Bela Vista",
            "Anchieta",
            "Angelina",
            "Anita Garibaldi",
            "Anit\u00e1polis",
            "Ant\u00f4nio Carlos",
            "Api\u00fana",
            "Arabut\u00e3",
            "Araquari",
            "Ararangu\u00e1",
            "Armaz\u00e9m",
            "Arroio Trinta",
            "Arvoredo",
            "Ascurra",
            "Atalanta",
            "Aurora",
            "Balne\u00e1rio Arroio do Silva",
            "Balne\u00e1rio Barra do Sul",
            "Balne\u00e1rio Cambori\u00fa",
            "Balne\u00e1rio Gaivota",
            "Balne\u00e1rio Pi\u00e7arras",
            "Balne\u00e1rio Rinc\u00e3o",
            "Bandeirante",
            "Barra Bonita",
            "Barra Velha",
            "Bela Vista do Toldo",
            "Belmonte",
            "Benedito Novo",
            "Bigua\u00e7u",
            "Blumenau",
            "Bocaina do Sul",
            "Bom Jardim da Serra",
            "Bom Jesus",
            "Bom Jesus do Oeste",
            "Bom Retiro",
            "Bombinhas",
            "Botuver\u00e1",
            "Bra\u00e7o do Norte",
            "Bra\u00e7o do Trombudo",
            "Brun\u00f3polis",
            "Brusque",
            "Ca\u00e7ador",
            "Caibi",
            "Calmon",
            "Cambori\u00fa",
            "Campo Alegre",
            "Campo Belo do Sul",
            "Campo Er\u00ea",
            "Campos Novos",
            "Canelinha",
            "Canoinhas",
            "Cap\u00e3o Alto",
            "Capinzal",
            "Capivari de Baixo",
            "Catanduvas",
            "Caxambu do Sul",
            "Celso Ramos",
            "Cerro Negro",
            "Chapad\u00e3o do Lageado",
            "Chapec\u00f3",
            "Cocal do Sul",
            "Conc\u00f3rdia",
            "Cordilheira Alta",
            "Coronel Freitas",
            "Coronel Martins",
            "Correia Pinto",
            "Corup\u00e1",
            "Crici\u00fama",
            "Cunha Por\u00e3",
            "Cunhata\u00ed",
            "Curitibanos",
            "Descanso",
            "Dion\u00edsio Cerqueira",
            "Dona Emma",
            "Doutor Pedrinho",
            "Entre Rios",
            "Ermo",
            "Erval Velho",
            "Faxinal dos Guedes",
            "Flor do Sert\u00e3o",
            "Florian\u00f3polis",
            "Formosa do Sul",
            "Forquilhinha",
            "Fraiburgo",
            "Frei Rog\u00e9rio",
            "Galv\u00e3o",
            "Garopaba",
            "Garuva",
            "Gaspar",
            "Governador Celso Ramos",
            "Gr\u00e3o Par\u00e1",
            "Gravatal",
            "Guabiruba",
            "Guaraciaba",
            "Guaramirim",
            "Guaruj\u00e1 do Sul",
            "Guatamb\u00fa",
            "Herval d'Oeste",
            "Ibiam",
            "Ibicar\u00e9",
            "Ibirama",
            "I\u00e7ara",
            "Ilhota",
            "Imaru\u00ed",
            "Imbituba",
            "Imbuia",
            "Indaial",
            "Iomer\u00ea",
            "Ipira",
            "Ipor\u00e3 do Oeste",
            "Ipua\u00e7u",
            "Ipumirim",
            "Iraceminha",
            "Irani",
            "Irati",
            "Irine\u00f3polis",
            "It\u00e1",
            "Itai\u00f3polis",
            "Itaja\u00ed",
            "Itapema",
            "Itapiranga",
            "Itapo\u00e1",
            "Ituporanga",
            "Jabor\u00e1",
            "Jacinto Machado",
            "Jaguaruna",
            "Jaragu\u00e1 do Sul",
            "Jardin\u00f3polis",
            "Joa\u00e7aba",
            "Joinville",
            "Jos\u00e9 Boiteux",
            "Jupi\u00e1",
            "Lacerd\u00f3polis",
            "Lages",
            "Laguna",
            "Lajeado Grande",
            "Laurentino",
            "Lauro Muller",
            "Lebon R\u00e9gis",
            "Leoberto Leal",
            "Lind\u00f3ia do Sul",
            "Lontras",
            "Luiz Alves",
            "Luzerna",
            "Macieira",
            "Mafra",
            "Major Gercino",
            "Major Vieira",
            "Maracaj\u00e1",
            "Maravilha",
            "Marema",
            "Massaranduba",
            "Matos Costa",
            "Meleiro",
            "Mirim Doce",
            "Modelo",
            "Monda\u00ed",
            "Monte Carlo",
            "Monte Castelo",
            "Morro da Fuma\u00e7a",
            "Morro Grande",
            "Navegantes",
            "Nova Erechim",
            "Nova Itaberaba",
            "Nova Trento",
            "Nova Veneza",
            "Novo Horizonte",
            "Orleans",
            "Otac\u00edlio Costa",
            "Ouro",
            "Ouro Verde",
            "Paial",
            "Painel",
            "Palho\u00e7a",
            "Palma Sola",
            "Palmeira",
            "Palmitos",
            "Papanduva",
            "Para\u00edso",
            "Passo de Torres",
            "Passos Maia",
            "Paulo Lopes",
            "Pedras Grandes",
            "Penha",
            "Peritiba",
            "Pescaria Brava",
            "Petrol\u00e2ndia",
            "Pinhalzinho",
            "Pinheiro Preto",
            "Piratuba",
            "Planalto Alegre",
            "Pomerode",
            "Ponte Alta",
            "Ponte Alta do Norte",
            "Ponte Serrada",
            "Porto Belo",
            "Porto Uni\u00e3o",
            "Pouso Redondo",
            "Praia Grande",
            "Presidente Castello Branco",
            "Presidente Get\u00falio",
            "Presidente Nereu",
            "Princesa",
            "Quilombo",
            "Rancho Queimado",
            "Rio das Antas",
            "Rio do Campo",
            "Rio do Oeste",
            "Rio do Sul",
            "Rio dos Cedros",
            "Rio Fortuna",
            "Rio Negrinho",
            "Rio Rufino",
            "Riqueza",
            "Rodeio",
            "Romel\u00e2ndia",
            "Salete",
            "Saltinho",
            "Salto Veloso",
            "Sang\u00e3o",
            "Santa Cec\u00edlia",
            "Santa Helena",
            "Santa Rosa de Lima",
            "Santa Rosa do Sul",
            "Santa Terezinha",
            "Santa Terezinha do Progresso",
            "Santiago do Sul",
            "Santo Amaro da Imperatriz",
            "S\u00e3o Bento do Sul",
            "S\u00e3o Bernardino",
            "S\u00e3o Bonif\u00e1cio",
            "S\u00e3o Carlos",
            "S\u00e3o Cristov\u00e3o do Sul",
            "S\u00e3o Domingos",
            "S\u00e3o Francisco do Sul",
            "S\u00e3o Jo\u00e3o Batista",
            "S\u00e3o Jo\u00e3o do Itaperi\u00fa",
            "S\u00e3o Jo\u00e3o do Oeste",
            "S\u00e3o Jo\u00e3o do Sul",
            "S\u00e3o Joaquim",
            "S\u00e3o Jos\u00e9",
            "S\u00e3o Jos\u00e9 do Cedro",
            "S\u00e3o Jos\u00e9 do Cerrito",
            "S\u00e3o Louren\u00e7o do Oeste",
            "S\u00e3o Ludgero",
            "S\u00e3o Martinho",
            "S\u00e3o Miguel da Boa Vista",
            "S\u00e3o Miguel do Oeste",
            "S\u00e3o Pedro de Alc\u00e2ntara",
            "Saudades",
            "Schroeder",
            "Seara",
            "Serra Alta",
            "Sider\u00f3polis",
            "Sombrio",
            "Sul Brasil",
            "Tai\u00f3",
            "Tangar\u00e1",
            "Tigrinhos",
            "Tijucas",
            "Timb\u00e9 do Sul",
            "Timb\u00f3",
            "Timb\u00f3 Grande",
            "Tr\u00eas Barras",
            "Treviso",
            "Treze de Maio",
            "Treze T\u00edlias",
            "Trombudo Central",
            "Tubar\u00e3o",
            "Tun\u00e1polis",
            "Turvo",
            "Uni\u00e3o do Oeste",
            "Urubici",
            "Urupema",
            "Urussanga",
            "Varge\u00e3o",
            "Vargem",
            "Vargem Bonita",
            "Vidal Ramos",
            "Videira",
            "Vitor Meireles",
            "Witmarsum",
            "Xanxer\u00ea",
            "Xavantina",
            "Xaxim",
            "Zort\u00e9a"
        ],
        RS: [
            "Acegu\u00e1",
            "\u00c1gua Santa",
            "Agudo",
            "Ajuricaba",
            "Alecrim",
            "Alegrete",
            "Alegria",
            "Almirante Tamandar\u00e9 do Sul",
            "Alpestre",
            "Alto Alegre",
            "Alto Feliz",
            "Alvorada",
            "Amaral Ferrador",
            "Ametista do Sul",
            "Andr\u00e9 da Rocha",
            "Anta Gorda",
            "Ant\u00f4nio Prado",
            "Arambar\u00e9",
            "Araric\u00e1",
            "Aratiba",
            "Arroio do Meio",
            "Arroio do Padre",
            "Arroio do Sal",
            "Arroio do Tigre",
            "Arroio dos Ratos",
            "Arroio Grande",
            "Arvorezinha",
            "Augusto Pestana",
            "\u00c1urea",
            "Bag\u00e9",
            "Balne\u00e1rio Pinhal",
            "Bar\u00e3o",
            "Bar\u00e3o de Cotegipe",
            "Bar\u00e3o do Triunfo",
            "Barra do Guarita",
            "Barra do Quara\u00ed",
            "Barra do Ribeiro",
            "Barra do Rio Azul",
            "Barra Funda",
            "Barrac\u00e3o",
            "Barros Cassal",
            "Benjamin Constant do Sul",
            "Bento Gon\u00e7alves",
            "Boa Vista das Miss\u00f5es",
            "Boa Vista do Buric\u00e1",
            "Boa Vista do Cadeado",
            "Boa Vista do Incra",
            "Boa Vista do Sul",
            "Bom Jesus",
            "Bom Princ\u00edpio",
            "Bom Progresso",
            "Bom Retiro do Sul",
            "Boqueir\u00e3o do Le\u00e3o",
            "Bossoroca",
            "Bozano",
            "Braga",
            "Brochier",
            "Buti\u00e1",
            "Ca\u00e7apava do Sul",
            "Cacequi",
            "Cachoeira do Sul",
            "Cachoeirinha",
            "Cacique Doble",
            "Caibat\u00e9",
            "Cai\u00e7ara",
            "Camaqu\u00e3",
            "Camargo",
            "Cambar\u00e1 do Sul",
            "Campestre da Serra",
            "Campina das Miss\u00f5es",
            "Campinas do Sul",
            "Campo Bom",
            "Campo Novo",
            "Campos Borges",
            "Candel\u00e1ria",
            "C\u00e2ndido God\u00f3i",
            "Candiota",
            "Canela",
            "Cangu\u00e7u",
            "Canoas",
            "Canudos do Vale",
            "Cap\u00e3o Bonito do Sul",
            "Cap\u00e3o da Canoa",
            "Cap\u00e3o do Cip\u00f3",
            "Cap\u00e3o do Le\u00e3o",
            "Capela de Santana",
            "Capit\u00e3o",
            "Capivari do Sul",
            "Cara\u00e1",
            "Carazinho",
            "Carlos Barbosa",
            "Carlos Gomes",
            "Casca",
            "Caseiros",
            "Catu\u00edpe",
            "Caxias do Sul",
            "Centen\u00e1rio",
            "Cerrito",
            "Cerro Branco",
            "Cerro Grande",
            "Cerro Grande do Sul",
            "Cerro Largo",
            "Chapada",
            "Charqueadas",
            "Charrua",
            "Chiapetta",
            "Chu\u00ed",
            "Chuvisca",
            "Cidreira",
            "Cir\u00edaco",
            "Colinas",
            "Colorado",
            "Condor",
            "Constantina",
            "Coqueiro Baixo",
            "Coqueiros do Sul",
            "Coronel Barros",
            "Coronel Bicaco",
            "Coronel Pilar",
            "Cotipor\u00e3",
            "Coxilha",
            "Crissiumal",
            "Cristal",
            "Cristal do Sul",
            "Cruz Alta",
            "Cruzaltense",
            "Cruzeiro do Sul",
            "David Canabarro",
            "Derrubadas",
            "Dezesseis de Novembro",
            "Dilermando de Aguiar",
            "Dois Irm\u00e3os",
            "Dois Irm\u00e3os das Miss\u00f5es",
            "Dois Lajeados",
            "Dom Feliciano",
            "Dom Pedrito",
            "Dom Pedro de Alc\u00e2ntara",
            "Dona Francisca",
            "Doutor Maur\u00edcio Cardoso",
            "Doutor Ricardo",
            "Eldorado do Sul",
            "Encantado",
            "Encruzilhada do Sul",
            "Engenho Velho",
            "Entre Rios do Sul",
            "Entre-Iju\u00eds",
            "Erebango",
            "Erechim",
            "Ernestina",
            "Erval Grande",
            "Erval Seco",
            "Esmeralda",
            "Esperan\u00e7a do Sul",
            "Espumoso",
            "Esta\u00e7\u00e3o",
            "Est\u00e2ncia Velha",
            "Esteio",
            "Estrela",
            "Estrela Velha",
            "Eug\u00eanio de Castro",
            "Fagundes Varela",
            "Farroupilha",
            "Faxinal do Soturno",
            "Faxinalzinho",
            "Fazenda Vilanova",
            "Feliz",
            "Flores da Cunha",
            "Floriano Peixoto",
            "Fontoura Xavier",
            "Formigueiro",
            "Forquetinha",
            "Fortaleza dos Valos",
            "Frederico Westphalen",
            "Garibaldi",
            "Garruchos",
            "Gaurama",
            "General C\u00e2mara",
            "Gentil",
            "Get\u00falio Vargas",
            "Giru\u00e1",
            "Glorinha",
            "Gramado",
            "Gramado dos Loureiros",
            "Gramado Xavier",
            "Gravata\u00ed",
            "Guabiju",
            "Gua\u00edba",
            "Guapor\u00e9",
            "Guarani das Miss\u00f5es",
            "Harmonia",
            "Herval",
            "Herveiras",
            "Horizontina",
            "Hulha Negra",
            "Humait\u00e1",
            "Ibarama",
            "Ibia\u00e7\u00e1",
            "Ibiraiaras",
            "Ibirapuit\u00e3",
            "Ibirub\u00e1",
            "Igrejinha",
            "Iju\u00ed",
            "Il\u00f3polis",
            "Imb\u00e9",
            "Imigrante",
            "Independ\u00eancia",
            "Inhacor\u00e1",
            "Ip\u00ea",
            "Ipiranga do Sul",
            "Ira\u00ed",
            "Itaara",
            "Itacurubi",
            "Itapuca",
            "Itaqui",
            "Itati",
            "Itatiba do Sul",
            "Ivor\u00e1",
            "Ivoti",
            "Jaboticaba",
            "Jacuizinho",
            "Jacutinga",
            "Jaguar\u00e3o",
            "Jaguari",
            "Jaquirana",
            "Jari",
            "J\u00f3ia",
            "J\u00falio de Castilhos",
            "Lagoa Bonita do Sul",
            "Lagoa dos Tr\u00eas Cantos",
            "Lagoa Vermelha",
            "Lago\u00e3o",
            "Lajeado",
            "Lajeado do Bugre",
            "Lavras do Sul",
            "Liberato Salzano",
            "Lindolfo Collor",
            "Linha Nova",
            "Ma\u00e7ambar\u00e1",
            "Machadinho",
            "Mampituba",
            "Manoel Viana",
            "Maquin\u00e9",
            "Marat\u00e1",
            "Marau",
            "Marcelino Ramos",
            "Mariana Pimentel",
            "Mariano Moro",
            "Marques de Souza",
            "Mata",
            "Mato Castelhano",
            "Mato Leit\u00e3o",
            "Mato Queimado",
            "Maximiliano de Almeida",
            "Minas do Le\u00e3o",
            "Miragua\u00ed",
            "Montauri",
            "Monte Alegre dos Campos",
            "Monte Belo do Sul",
            "Montenegro",
            "Morma\u00e7o",
            "Morrinhos do Sul",
            "Morro Redondo",
            "Morro Reuter",
            "Mostardas",
            "Mu\u00e7um",
            "Muitos Cap\u00f5es",
            "Muliterno",
            "N\u00e3o-Me-Toque",
            "Nicolau Vergueiro",
            "Nonoai",
            "Nova Alvorada",
            "Nova Ara\u00e7\u00e1",
            "Nova Bassano",
            "Nova Boa Vista",
            "Nova Br\u00e9scia",
            "Nova Candel\u00e1ria",
            "Nova Esperan\u00e7a do Sul",
            "Nova Hartz",
            "Nova P\u00e1dua",
            "Nova Palma",
            "Nova Petr\u00f3polis",
            "Nova Prata",
            "Nova Ramada",
            "Nova Roma do Sul",
            "Nova Santa Rita",
            "Novo Barreiro",
            "Novo Cabrais",
            "Novo Hamburgo",
            "Novo Machado",
            "Novo Tiradentes",
            "Novo Xingu",
            "Os\u00f3rio",
            "Paim Filho",
            "Palmares do Sul",
            "Palmeira das Miss\u00f5es",
            "Palmitinho",
            "Panambi",
            "Pantano Grande",
            "Para\u00ed",
            "Para\u00edso do Sul",
            "Pareci Novo",
            "Parob\u00e9",
            "Passa Sete",
            "Passo do Sobrado",
            "Passo Fundo",
            "Paulo Bento",
            "Paverama",
            "Pedras Altas",
            "Pedro Os\u00f3rio",
            "Peju\u00e7ara",
            "Pelotas",
            "Picada Caf\u00e9",
            "Pinhal",
            "Pinhal da Serra",
            "Pinhal Grande",
            "Pinheirinho do Vale",
            "Pinheiro Machado",
            "Pinto Bandeira",
            "Pirap\u00f3",
            "Piratini",
            "Planalto",
            "Po\u00e7o das Antas",
            "Pont\u00e3o",
            "Ponte Preta",
            "Port\u00e3o",
            "Porto Alegre",
            "Porto Lucena",
            "Porto Mau\u00e1",
            "Porto Vera Cruz",
            "Porto Xavier",
            "Pouso Novo",
            "Presidente Lucena",
            "Progresso",
            "Prot\u00e1sio Alves",
            "Putinga",
            "Quara\u00ed",
            "Quatro Irm\u00e3os",
            "Quevedos",
            "Quinze de Novembro",
            "Redentora",
            "Relvado",
            "Restinga S\u00eaca",
            "Rio dos \u00cdndios",
            "Rio Grande",
            "Rio Pardo",
            "Riozinho",
            "Roca Sales",
            "Rodeio Bonito",
            "Rolador",
            "Rolante",
            "Ronda Alta",
            "Rondinha",
            "Roque Gonzales",
            "Ros\u00e1rio do Sul",
            "Sagrada Fam\u00edlia",
            "Saldanha Marinho",
            "Salto do Jacu\u00ed",
            "Salvador das Miss\u00f5es",
            "Salvador do Sul",
            "Sananduva",
            "Sant'Ana do Livramento",
            "Santa B\u00e1rbara do Sul",
            "Santa Cec\u00edlia do Sul",
            "Santa Clara do Sul",
            "Santa Cruz do Sul",
            "Santa Margarida do Sul",
            "Santa Maria",
            "Santa Maria do Herval",
            "Santa Rosa",
            "Santa Tereza",
            "Santa Vit\u00f3ria do Palmar",
            "Santana da Boa Vista",
            "Santiago",
            "Santo \u00c2ngelo",
            "Santo Ant\u00f4nio da Patrulha",
            "Santo Ant\u00f4nio das Miss\u00f5es",
            "Santo Ant\u00f4nio do Palma",
            "Santo Ant\u00f4nio do Planalto",
            "Santo Augusto",
            "Santo Cristo",
            "Santo Expedito do Sul",
            "S\u00e3o Borja",
            "S\u00e3o Domingos do Sul",
            "S\u00e3o Francisco de Assis",
            "S\u00e3o Francisco de Paula",
            "S\u00e3o Gabriel",
            "S\u00e3o Jer\u00f4nimo",
            "S\u00e3o Jo\u00e3o da Urtiga",
            "S\u00e3o Jo\u00e3o do Pol\u00easine",
            "S\u00e3o Jorge",
            "S\u00e3o Jos\u00e9 das Miss\u00f5es",
            "S\u00e3o Jos\u00e9 do Herval",
            "S\u00e3o Jos\u00e9 do Hort\u00eancio",
            "S\u00e3o Jos\u00e9 do Inhacor\u00e1",
            "S\u00e3o Jos\u00e9 do Norte",
            "S\u00e3o Jos\u00e9 do Ouro",
            "S\u00e3o Jos\u00e9 do Sul",
            "S\u00e3o Jos\u00e9 dos Ausentes",
            "S\u00e3o Leopoldo",
            "S\u00e3o Louren\u00e7o do Sul",
            "S\u00e3o Luiz Gonzaga",
            "S\u00e3o Marcos",
            "S\u00e3o Martinho",
            "S\u00e3o Martinho da Serra",
            "S\u00e3o Miguel das Miss\u00f5es",
            "S\u00e3o Nicolau",
            "S\u00e3o Paulo das Miss\u00f5es",
            "S\u00e3o Pedro da Serra",
            "S\u00e3o Pedro das Miss\u00f5es",
            "S\u00e3o Pedro do Buti\u00e1",
            "S\u00e3o Pedro do Sul",
            "S\u00e3o Sebasti\u00e3o do Ca\u00ed",
            "S\u00e3o Sep\u00e9",
            "S\u00e3o Valentim",
            "S\u00e3o Valentim do Sul",
            "S\u00e3o Val\u00e9rio do Sul",
            "S\u00e3o Vendelino",
            "S\u00e3o Vicente do Sul",
            "Sapiranga",
            "Sapucaia do Sul",
            "Sarandi",
            "Seberi",
            "Sede Nova",
            "Segredo",
            "Selbach",
            "Senador Salgado Filho",
            "Sentinela do Sul",
            "Serafina Corr\u00eaa",
            "S\u00e9rio",
            "Sert\u00e3o",
            "Sert\u00e3o Santana",
            "Sete de Setembro",
            "Severiano de Almeida",
            "Silveira Martins",
            "Sinimbu",
            "Sobradinho",
            "Soledade",
            "Taba\u00ed",
            "Tapejara",
            "Tapera",
            "Tapes",
            "Taquara",
            "Taquari",
            "Taquaru\u00e7u do Sul",
            "Tavares",
            "Tenente Portela",
            "Terra de Areia",
            "Teut\u00f4nia",
            "Tio Hugo",
            "Tiradentes do Sul",
            "Toropi",
            "Torres",
            "Tramanda\u00ed",
            "Travesseiro",
            "Tr\u00eas Arroios",
            "Tr\u00eas Cachoeiras",
            "Tr\u00eas Coroas",
            "Tr\u00eas de Maio",
            "Tr\u00eas Forquilhas",
            "Tr\u00eas Palmeiras",
            "Tr\u00eas Passos",
            "Trindade do Sul",
            "Triunfo",
            "Tucunduva",
            "Tunas",
            "Tupanci do Sul",
            "Tupanciret\u00e3",
            "Tupandi",
            "Tuparendi",
            "Turu\u00e7u",
            "Ubiretama",
            "Uni\u00e3o da Serra",
            "Unistalda",
            "Uruguaiana",
            "Vacaria",
            "Vale do Sol",
            "Vale Real",
            "Vale Verde",
            "Vanini",
            "Ven\u00e2ncio Aires",
            "Vera Cruz",
            "Veran\u00f3polis",
            "Vespasiano Corr\u00eaa",
            "Viadutos",
            "Viam\u00e3o",
            "Vicente Dutra",
            "Victor Graeff",
            "Vila Flores",
            "Vila L\u00e2ngaro",
            "Vila Maria",
            "Vila Nova do Sul",
            "Vista Alegre",
            "Vista Alegre do Prata",
            "Vista Ga\u00facha",
            "Vit\u00f3ria das Miss\u00f5es",
            "Westf\u00e1lia",
            "Xangri-l\u00e1"
        ],
        MS: [
            "\u00c1gua Clara",
            "Alcin\u00f3polis",
            "Amambai",
            "Anast\u00e1cio",
            "Anauril\u00e2ndia",
            "Ang\u00e9lica",
            "Ant\u00f4nio Jo\u00e3o",
            "Aparecida do Taboado",
            "Aquidauana",
            "Aral Moreira",
            "Bandeirantes",
            "Bataguassu",
            "Bataypor\u00e3",
            "Bela Vista",
            "Bodoquena",
            "Bonito",
            "Brasil\u00e2ndia",
            "Caarap\u00f3",
            "Camapu\u00e3",
            "Campo Grande",
            "Caracol",
            "Cassil\u00e2ndia",
            "Chapad\u00e3o do Sul",
            "Corguinho",
            "Coronel Sapucaia",
            "Corumb\u00e1",
            "Costa Rica",
            "Coxim",
            "Deod\u00e1polis",
            "Dois Irm\u00e3os do Buriti",
            "Douradina",
            "Dourados",
            "Eldorado",
            "F\u00e1tima do Sul",
            "Figueir\u00e3o",
            "Gl\u00f3ria de Dourados",
            "Guia Lopes da Laguna",
            "Iguatemi",
            "Inoc\u00eancia",
            "Itapor\u00e3",
            "Itaquira\u00ed",
            "Ivinhema",
            "Japor\u00e3",
            "Jaraguari",
            "Jardim",
            "Jate\u00ed",
            "Juti",
            "Lad\u00e1rio",
            "Laguna Carap\u00e3",
            "Maracaju",
            "Miranda",
            "Mundo Novo",
            "Navira\u00ed",
            "Nioaque",
            "Nova Alvorada do Sul",
            "Nova Andradina",
            "Novo Horizonte do Sul",
            "Para\u00edso das \u00c1guas",
            "Parana\u00edba",
            "Paranhos",
            "Pedro Gomes",
            "Ponta Por\u00e3",
            "Porto Murtinho",
            "Ribas do Rio Pardo",
            "Rio Brilhante",
            "Rio Negro",
            "Rio Verde de Mato Grosso",
            "Rochedo",
            "Santa Rita do Pardo",
            "S\u00e3o Gabriel do Oeste",
            "Selv\u00edria",
            "Sete Quedas",
            "Sidrol\u00e2ndia",
            "Sonora",
            "Tacuru",
            "Taquarussu",
            "Terenos",
            "Tr\u00eas Lagoas",
            "Vicentina"
        ],
        MT: [
            "Acorizal",
            "\u00c1gua Boa",
            "Alta Floresta",
            "Alto Araguaia",
            "Alto Boa Vista",
            "Alto Gar\u00e7as",
            "Alto Paraguai",
            "Alto Taquari",
            "Apiac\u00e1s",
            "Araguaiana",
            "Araguainha",
            "Araputanga",
            "Aren\u00e1polis",
            "Aripuan\u00e3",
            "Bar\u00e3o de Melga\u00e7o",
            "Barra do Bugres",
            "Barra do Gar\u00e7as",
            "Bom Jesus do Araguaia",
            "Brasnorte",
            "C\u00e1ceres",
            "Campin\u00e1polis",
            "Campo Novo do Parecis",
            "Campo Verde",
            "Campos de J\u00falio",
            "Canabrava do Norte",
            "Canarana",
            "Carlinda",
            "Castanheira",
            "Chapada dos Guimar\u00e3es",
            "Cl\u00e1udia",
            "Cocalinho",
            "Col\u00edder",
            "Colniza",
            "Comodoro",
            "Confresa",
            "Conquista D'Oeste",
            "Cotrigua\u00e7u",
            "Cuiab\u00e1",
            "Curvel\u00e2ndia",
            "Denise",
            "Diamantino",
            "Dom Aquino",
            "Feliz Natal",
            "Figueir\u00f3polis D'Oeste",
            "Ga\u00facha do Norte",
            "General Carneiro",
            "Gl\u00f3ria D'Oeste",
            "Guarant\u00e3 do Norte",
            "Guiratinga",
            "Indiava\u00ed",
            "Ipiranga do Norte",
            "Itanhang\u00e1",
            "Ita\u00faba",
            "Itiquira",
            "Jaciara",
            "Jangada",
            "Jauru",
            "Juara",
            "Ju\u00edna",
            "Juruena",
            "Juscimeira",
            "Lambari D'Oeste",
            "Lucas do Rio Verde",
            "Luciara",
            "Marcel\u00e2ndia",
            "Matup\u00e1",
            "Mirassol d'Oeste",
            "Nobres",
            "Nortel\u00e2ndia",
            "Nossa Senhora do Livramento",
            "Nova Bandeirantes",
            "Nova Brasil\u00e2ndia",
            "Nova Cana\u00e3 do Norte",
            "Nova Guarita",
            "Nova Lacerda",
            "Nova Maril\u00e2ndia",
            "Nova Maring\u00e1",
            "Nova Monte Verde",
            "Nova Mutum",
            "Nova Nazar\u00e9",
            "Nova Ol\u00edmpia",
            "Nova Santa Helena",
            "Nova Ubirat\u00e3",
            "Nova Xavantina",
            "Novo Horizonte do Norte",
            "Novo Mundo",
            "Novo Santo Ant\u00f4nio",
            "Novo S\u00e3o Joaquim",
            "Parana\u00edta",
            "Paranatinga",
            "Pedra Preta",
            "Peixoto de Azevedo",
            "Planalto da Serra",
            "Pocon\u00e9",
            "Pontal do Araguaia",
            "Ponte Branca",
            "Pontes e Lacerda",
            "Porto Alegre do Norte",
            "Porto dos Ga\u00fachos",
            "Porto Esperidi\u00e3o",
            "Porto Estrela",
            "Poxor\u00e9u",
            "Primavera do Leste",
            "Quer\u00eancia",
            "Reserva do Caba\u00e7al",
            "Ribeir\u00e3o Cascalheira",
            "Ribeir\u00e3ozinho",
            "Rio Branco",
            "Rondol\u00e2ndia",
            "Rondon\u00f3polis",
            "Ros\u00e1rio Oeste",
            "Salto do C\u00e9u",
            "Santa Carmem",
            "Santa Cruz do Xingu",
            "Santa Rita do Trivelato",
            "Santa Terezinha",
            "Santo Afonso",
            "Santo Ant\u00f4nio do Leste",
            "Santo Ant\u00f4nio do Leverger",
            "S\u00e3o F\u00e9lix do Araguaia",
            "S\u00e3o Jos\u00e9 do Povo",
            "S\u00e3o Jos\u00e9 do Rio Claro",
            "S\u00e3o Jos\u00e9 do Xingu",
            "S\u00e3o Jos\u00e9 dos Quatro Marcos",
            "S\u00e3o Pedro da Cipa",
            "Sapezal",
            "Serra Nova Dourada",
            "Sinop",
            "Sorriso",
            "Tabapor\u00e3",
            "Tangar\u00e1 da Serra",
            "Tapurah",
            "Terra Nova do Norte",
            "Tesouro",
            "Torixor\u00e9u",
            "Uni\u00e3o do Sul",
            "Vale de S\u00e3o Domingos",
            "V\u00e1rzea Grande",
            "Vera",
            "Vila Bela da Sant\u00edssima Trindade",
            "Vila Rica"
        ],
        GO: [
            "Abadia de Goi\u00e1s",
            "Abadi\u00e2nia",
            "Acre\u00fana",
            "Adel\u00e2ndia",
            "\u00c1gua Fria de Goi\u00e1s",
            "\u00c1gua Limpa",
            "\u00c1guas Lindas de Goi\u00e1s",
            "Alex\u00e2nia",
            "Alo\u00e2ndia",
            "Alto Horizonte",
            "Alto Para\u00edso de Goi\u00e1s",
            "Alvorada do Norte",
            "Amaralina",
            "Americano do Brasil",
            "Amorin\u00f3polis",
            "An\u00e1polis",
            "Anhanguera",
            "Anicuns",
            "Aparecida de Goi\u00e2nia",
            "Aparecida do Rio Doce",
            "Apor\u00e9",
            "Ara\u00e7u",
            "Aragar\u00e7as",
            "Aragoi\u00e2nia",
            "Araguapaz",
            "Aren\u00f3polis",
            "Aruan\u00e3",
            "Auril\u00e2ndia",
            "Avelin\u00f3polis",
            "Baliza",
            "Barro Alto",
            "Bela Vista de Goi\u00e1s",
            "Bom Jardim de Goi\u00e1s",
            "Bom Jesus de Goi\u00e1s",
            "Bonfin\u00f3polis",
            "Bon\u00f3polis",
            "Brazabrantes",
            "Brit\u00e2nia",
            "Buriti Alegre",
            "Buriti de Goi\u00e1s",
            "Buritin\u00f3polis",
            "Cabeceiras",
            "Cachoeira Alta",
            "Cachoeira de Goi\u00e1s",
            "Cachoeira Dourada",
            "Ca\u00e7u",
            "Caiap\u00f4nia",
            "Caldas Novas",
            "Caldazinha",
            "Campestre de Goi\u00e1s",
            "Campina\u00e7u",
            "Campinorte",
            "Campo Alegre de Goi\u00e1s",
            "Campo Limpo de Goi\u00e1s",
            "Campos Belos",
            "Campos Verdes",
            "Carmo do Rio Verde",
            "Castel\u00e2ndia",
            "Catal\u00e3o",
            "Catura\u00ed",
            "Cavalcante",
            "Ceres",
            "Cezarina",
            "Chapad\u00e3o do C\u00e9u",
            "Cidade Ocidental",
            "Cocalzinho de Goi\u00e1s",
            "Colinas do Sul",
            "C\u00f3rrego do Ouro",
            "Corumb\u00e1 de Goi\u00e1s",
            "Corumba\u00edba",
            "Cristalina",
            "Cristian\u00f3polis",
            "Crix\u00e1s",
            "Crom\u00ednia",
            "Cumari",
            "Damian\u00f3polis",
            "Damol\u00e2ndia",
            "Davin\u00f3polis",
            "Diorama",
            "Divin\u00f3polis de Goi\u00e1s",
            "Doverl\u00e2ndia",
            "Edealina",
            "Ed\u00e9ia",
            "Estrela do Norte",
            "Faina",
            "Fazenda Nova",
            "Firmin\u00f3polis",
            "Flores de Goi\u00e1s",
            "Formosa",
            "Formoso",
            "Gameleira de Goi\u00e1s",
            "Goian\u00e1polis",
            "Goiandira",
            "Goian\u00e9sia",
            "Goi\u00e2nia",
            "Goianira",
            "Goi\u00e1s",
            "Goiatuba",
            "Gouvel\u00e2ndia",
            "Guap\u00f3",
            "Guara\u00edta",
            "Guarani de Goi\u00e1s",
            "Guarinos",
            "Heitora\u00ed",
            "Hidrol\u00e2ndia",
            "Hidrolina",
            "Iaciara",
            "Inaciol\u00e2ndia",
            "Indiara",
            "Inhumas",
            "Ipameri",
            "Ipiranga de Goi\u00e1s",
            "Ipor\u00e1",
            "Israel\u00e2ndia",
            "Itabera\u00ed",
            "Itaguari",
            "Itaguaru",
            "Itaj\u00e1",
            "Itapaci",
            "Itapirapu\u00e3",
            "Itapuranga",
            "Itarum\u00e3",
            "Itau\u00e7u",
            "Itumbiara",
            "Ivol\u00e2ndia",
            "Jandaia",
            "Jaragu\u00e1",
            "Jata\u00ed",
            "Jaupaci",
            "Jes\u00fapolis",
            "Jovi\u00e2nia",
            "Jussara",
            "Lagoa Santa",
            "Leopoldo de Bulh\u00f5es",
            "Luzi\u00e2nia",
            "Mairipotaba",
            "Mamba\u00ed",
            "Mara Rosa",
            "Marzag\u00e3o",
            "Matrinch\u00e3",
            "Mauril\u00e2ndia",
            "Mimoso de Goi\u00e1s",
            "Mina\u00e7u",
            "Mineiros",
            "Moipor\u00e1",
            "Monte Alegre de Goi\u00e1s",
            "Montes Claros de Goi\u00e1s",
            "Montividiu",
            "Montividiu do Norte",
            "Morrinhos",
            "Morro Agudo de Goi\u00e1s",
            "Moss\u00e2medes",
            "Mozarl\u00e2ndia",
            "Mundo Novo",
            "Mutun\u00f3polis",
            "Naz\u00e1rio",
            "Ner\u00f3polis",
            "Niquel\u00e2ndia",
            "Nova Am\u00e9rica",
            "Nova Aurora",
            "Nova Crix\u00e1s",
            "Nova Gl\u00f3ria",
            "Nova Igua\u00e7u de Goi\u00e1s",
            "Nova Roma",
            "Nova Veneza",
            "Novo Brasil",
            "Novo Gama",
            "Novo Planalto",
            "Orizona",
            "Ouro Verde de Goi\u00e1s",
            "Ouvidor",
            "Padre Bernardo",
            "Palestina de Goi\u00e1s",
            "Palmeiras de Goi\u00e1s",
            "Palmelo",
            "Palmin\u00f3polis",
            "Panam\u00e1",
            "Paranaiguara",
            "Para\u00fana",
            "Perol\u00e2ndia",
            "Petrolina de Goi\u00e1s",
            "Pilar de Goi\u00e1s",
            "Piracanjuba",
            "Piranhas",
            "Piren\u00f3polis",
            "Pires do Rio",
            "Planaltina",
            "Pontalina",
            "Porangatu",
            "Porteir\u00e3o",
            "Portel\u00e2ndia",
            "Posse",
            "Professor Jamil",
            "Quirin\u00f3polis",
            "Rialma",
            "Rian\u00e1polis",
            "Rio Quente",
            "Rio Verde",
            "Rubiataba",
            "Sanclerl\u00e2ndia",
            "Santa B\u00e1rbara de Goi\u00e1s",
            "Santa Cruz de Goi\u00e1s",
            "Santa F\u00e9 de Goi\u00e1s",
            "Santa Helena de Goi\u00e1s",
            "Santa Isabel",
            "Santa Rita do Araguaia",
            "Santa Rita do Novo Destino",
            "Santa Rosa de Goi\u00e1s",
            "Santa Tereza de Goi\u00e1s",
            "Santa Terezinha de Goi\u00e1s",
            "Santo Ant\u00f4nio da Barra",
            "Santo Ant\u00f4nio de Goi\u00e1s",
            "Santo Ant\u00f4nio do Descoberto",
            "S\u00e3o Domingos",
            "S\u00e3o Francisco de Goi\u00e1s",
            "S\u00e3o Jo\u00e3o d'Alian\u00e7a",
            "S\u00e3o Jo\u00e3o da Para\u00fana",
            "S\u00e3o Lu\u00eds de Montes Belos",
            "S\u00e3o Luiz do Norte",
            "S\u00e3o Miguel do Araguaia",
            "S\u00e3o Miguel do Passa Quatro",
            "S\u00e3o Patr\u00edcio",
            "S\u00e3o Sim\u00e3o",
            "Senador Canedo",
            "Serran\u00f3polis",
            "Silv\u00e2nia",
            "Simol\u00e2ndia",
            "S\u00edtio d'Abadia",
            "Taquaral de Goi\u00e1s",
            "Teresina de Goi\u00e1s",
            "Terez\u00f3polis de Goi\u00e1s",
            "Tr\u00eas Ranchos",
            "Trindade",
            "Trombas",
            "Turv\u00e2nia",
            "Turvel\u00e2ndia",
            "Uirapuru",
            "Urua\u00e7u",
            "Uruana",
            "Uruta\u00ed",
            "Valpara\u00edso de Goi\u00e1s",
            "Varj\u00e3o",
            "Vian\u00f3polis",
            "Vicentin\u00f3polis",
            "Vila Boa",
            "Vila Prop\u00edcio"
        ],
        DF: [
            "Bras\u00edlia"
        ]
    }
})